import * as actionTypes from '../actions/action';

//Redux: https://redux.js.org/basics/reducers
const initialState = {
    login: false,
    searchString: '',
    search: false,
    articles: [],
    sortType: 'relevance',
    periodType: 'lastQuarter',
    startDate: new Date(),
    endDate: new Date(),
    source: [],
    transactionCostIdeal: 0.29
}

//Redux: https://redux.js.org/basics/reducers
const reducer = (state = initialState, action) => {
    switch(action.type) {
        //Login
        case actionTypes.SET_LOGINTOKEN: 
        return {
            ...state,
            logintoken: action.value
        }
        case actionTypes.SET_USERNAME:
        return {
            ...state,
            userName: action.value
        }
        case actionTypes.SET_BALANCE:
        return {
            ...state,
            balance: action.value
        }
        case actionTypes.SET_CUSTOMERLINKID:
        return {
            ...state,
            customerLinkId: action.value
        }
        case actionTypes.SET_DISPLAYNAME:
        return {
            ...state,
            displayName: action.value
        }
        case actionTypes.SET_SENDEMAILADDRESS:
        return {
            ...state,
            sendEmailAddress: action.value
        }
        case actionTypes.SET_USERID:
        return {
            ...state,
            userId: action.value
        }

        // Search/Filters
        case actionTypes.SORT_TYPE: 
        return {
            ...state,
            sortType: action.value
        }
        case actionTypes.PERIOD_TYPE: 
        return {
            ...state,
            periodType: action.value
        }
        case actionTypes.START_DATE: 
        return {
            ...state,
            startDate: action.value
        }
        case actionTypes.END_DATE: 
        return {
            ...state,
            endDate: action.value
        }
        case actionTypes.SEARCH_STRING: 
        return {
            ...state,
            searchString: action.value
        }
        case actionTypes.SEARCH_BOOLEAN: 
        return {
            ...state,
            search: action.value
        }
        case actionTypes.SOURCE:
        if (action.value.length > 0 && state.source !== action.value) {
            return {
                ...state,
                source: [
                    action.value
                ]
            }
        }
        else if (state.source === action.value) {
            return {
                ...state,
                source: action.value
            }
        }
        else {
            return {
                ...state,
                source: []
            }
        }

        //Auth
        case actionTypes.IS_AUTHENTICATED: 
        return {
            ...state,
            login: action.value
        }
        case actionTypes.IS_WHITELABEL: 
        return {
            ...state,
            whitelabel: action.value
        }
        case actionTypes.SET_WHITELABELHOST:
        return {
            ...state,
            whitelabelHost: action.value
        }

        //Cart
        case actionTypes.SET_CART:
        return {
            ...state,
            cart: action.value
        }
        case actionTypes.ADD_TO_CART:
        return {
            ...state,
            cart: [
                ...state.cart,
                ...action.payload
            ],
            
        }
        case actionTypes.REMOVE_FROM_CART:
        return {
            ...state,
            cart: [ 
                ...state.cart.slice(0, action.payload),
                ...state.cart.slice(action.payload + 1)
            ]
        }
        default:
            return state;
        }
};

export default reducer;