import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import "./Forgot.css";

/**
 * @author Donny Smit
 * rest
 * @author Jeffrey Hendrikse
 * componentWillMount
 * axios api call
 *
 * Component for recieving an email in case of forgotten password
 */
class Forgot extends Component {
  state = {
    forgotForm: {
      email: {
        elementType: "input_2",
        elementConfig: {
          id: "email",
          type: "email",
          placeholder: "Vul uw e-mailadres in"
        },
        value: "",
        label: "E-mailadres",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    }
  };

  componentWillMount() {
    if (this.props.auth) {
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  //Handles reset password request API which will never fail, if the email doesn't exist in the database a mail doesn't get sent.
  sendEmailHandler = event => {
    event.preventDefault();
    let params = new URLSearchParams();
    params.append("username", this.state.forgotForm.email.value);
    params.append("type", "ArtikelGemist");

    axios
      .post("auth/reset_password_request", params)
      .then(() => {
        toast.success(
          <p id="emailSentSucces">
            Er is een e-mail verstuurd naar:
            <br />
            {this.state.forgotForm.email.value}
          </p>,
          {
            position: "top-right",
            autoClose: 50000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      });
  };

  /** Checks validity of input fields, see validation in state. Returns boolean */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedforgotForm = {
      ...this.state.forgotForm
    };
    const updatedFormElement = {
      ...updatedforgotForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedforgotForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedforgotForm) {
      formIsValid = updatedforgotForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ forgotForm: updatedforgotForm, formIsValid: formIsValid });
  };

  render() {
    //Transforms form to array necessary for mapping later in return function
    const formElementsArray = [];
    for (let key in this.state.forgotForm) {
      formElementsArray.push({
        id: key,
        config: this.state.forgotForm[key]
      });
    }

    return (
      <div className="ForgotContent">
        <div className="ForgotBox">
          <h1>WACHTWOORD VERGETEN</h1>
          <h5>
            Na het invoeren van uw e-mailadres krijgt u van ons een e-mail
            toegestuurd voor het resetten van uw wachtwoord.
          </h5>
          <form onSubmit={() => this.sendEmailHandler()}>
            {formElementsArray.map(formElement => (
              <Input
                id={formElement.config.elementConfig.id}
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
            ))}
            <Button id="forgotButton" clicked={this.sendEmailHandler}>
              Verstuur
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

//React Redux
const mapStateToProps = state => {
  return {
    auth: state.login
  };
};

export default connect(mapStateToProps)(Forgot);
