import React from "react";

import "./Legal.css";

/**
 * @author Elisa Straatman
 *
 * Legal documents
 */
const aboutUs = () => (
  <div className="Legal" id="about">
    <h1>Over Ons</h1>
    <p>
      De artikelnabesteldienst Artikelgemist.nl is een initiatief van Profactys
      BV in samenwerking met uitgevers. In Artikelgemist.nl treft u
      gepubliceerde artikelen aan uit een verscheidenheid van Nederlandse
      kranten, tijdschriften en vakbladen. De gepubliceerde artikelen zijn tegen
      een vergoeding opvraagbaar.
    </p>
    <p>
      Op de homepage treft u een overzicht aan van de deelnemende uitgevers en
      beschikbare publicaties. Mochten er voor u belangrijke uitgevers en/of
      uitgaven ontbreken, dan horen wij dat graag. U kunt dit kenbaar maken door
      eenvoudig een e-mail te sturen naar info@artikelgemist.nl.
    </p>

    <h4>Spelregels</h4>
    <p>
      De prijs per gepubliceerd artikel is onder andere afhankelijk van het
      soort publicatie en wordt mede vastgesteld door de uitgever. In de
      artikelprijs is de auteursrechtelijke vergoeding verdisconteerd.
    </p>
  </div>
);

export default aboutUs;
