import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import "./DeleteAccount.css";
import { toast } from "react-toastify";

/**
 * @author Donny Smit
 *
 * Component for the deletion of an account from an user by the user.
 */
class DeleteAccount extends Component {
  state = {
    deleteAccountForm: {
      deleteAccount: {
        elementType: "input_2",
        elementConfig: {
          id: "deleteAccount",
          type: "password",
          placeholder: "Vul uw wachtwoord in ter bevestiging..."
        },
        value: "",
        label: "Vul uw wachtwoord in ter bevestiging",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    correctPassword: false,
    buttonClicked: false
  };

  /**
   * [1/3] Deletes an account when clicked on the delete button.
   */
  buttonClickedHandler = event => {
    event.preventDefault();
    if (this.state.formIsValid) {
      let params = new URLSearchParams();
      params.append("username", this.props.userName);
      params.append(
        "password",
        this.state.deleteAccountForm.deleteAccount.value
      );
      axios
        .post("auth/login", params)
        .then(response => {
          if (response.data.token === this.props.logintoken) {
            this.getAppTokenHandler();
          }
        })
        .catch(error => {
          toast.error("Wachtwoord onjuist.");
        });
    }
  };

  /**
   * [2/3] Retrieve the application token used to change the display name.
   */
  getAppTokenHandler = () => {
    if (this.state.formIsValid) {
      let loginParams = new URLSearchParams();
      loginParams.append("token", this.props.logintoken);
      axios.post("auth/token", loginParams).then(response => {
        this.deleteAccountHandler(response.data.token);
      });
    }
  };

  /**
   * [3/3] Change the user's send email address
   * @param appToken
   */
  deleteAccountHandler = appToken => {
    axios
      .delete("user/crud/" + this.props.userId + "?token=" + appToken)
      .then(response => {
        toast.info(<p>Account succesvol verwijderd. U wordt nu uitgelogd.</p>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        this.props.history.push("/logout");
      });
  };

  /**
   * Checks validity of input fields, see validation in state. Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedDeleteAccountForm = {
      ...this.state.deleteAccountForm
    };
    const updatedFormElement = {
      ...updatedDeleteAccountForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedDeleteAccountForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedDeleteAccountForm) {
      formIsValid =
        updatedDeleteAccountForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      deleteAccountForm: updatedDeleteAccountForm,
      formIsValid: formIsValid
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.deleteAccountForm) {
      formElementsArray.push({
        id: key,
        config: this.state.deleteAccountForm[key]
      });
    }

    return (
      <div className="DeleteAccount">
        <h1>Account verwijderen</h1>
        <p>
          Het verwijderen van uw account zorgt ervoor dat alle informatie
          verdwijnt. Weet u het zeker?
          <br />
          <br />
          <b>
            Let op! Uw saldo verdwijnt met het verwijderen van uw account. U
            kunt geen nieuw account aanmaken met hetzelfde e-mailadres.
          </b>
        </p>
        <form onSubmit={this.buttonClickedHandler}>
          {formElementsArray.map(formElement => (
            <div key={formElement.id}>
              <Input
                id={formElement.config.elementConfig.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
            </div>
          ))}
          <Button id="deleteButton" clicked={this.buttonClickedHandler}>
            Verwijderen
          </Button>
        </form>
      </div>
    );
  }
}

//Redux: https://redux.js.org/
const mapStateToProps = state => {
  return {
    userName: state.userName,
    userId: state.userId,
    logintoken: state.logintoken
  };
};

export default connect(mapStateToProps)(DeleteAccount);