import React, { Component } from "react";

import { connect } from "react-redux";
import { Route } from "react-router-dom";

import ChangePassword from "../../components/Settings/ChangePassword/ChangePassword";
import ChangeName from "../../components/Settings/ChangeName/ChangeName";
import ChangeMail from "../../components/Settings/ChangeMail/ChangeMail";
import Balance from "../../components/Settings/Balance/Balance";
import DeleteAccount from "../../components/Settings/DeleteAccount/DeleteAccount";
import "./Settings.css";
import SettingsNavigation from "../../components/Navigation/SettingsNavigation/SettingsNavigation";
import OrderHistory from "../../components/Order/OrderHistory/OrderHistory";

/**
 * @author Donny Smit
 *
 * Represents the main Settings which includes the nested routes inside of it, together with the settings side navigation.
 */
class Settings extends Component {
  state = {
    showSideDrawer: false
  };

  /*
   * Criterium that has to be met before the user reaches Settings.
   */
  componentWillMount() {
    if ((this.props.auth && this.props.whitelabel) || !this.props.auth) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="SettingsContent">
        <div className="Settings">
          <div className="SettingsNavigation">
            <SettingsNavigation {...this.props} />
          </div>
          <Route
            path="/settings"
            exact
            render={() => (
              <>
                <div className="SettingsDesktop">
                  <h1>Instellingen</h1>
                  <p>
                    Kies één van de instellingen hiernaast om uw gegevens te
                    wijzigen.{" "}
                  </p>
                </div>
                <div className="SettingsMobile">
                  <h1>Instellingen</h1>
                  <p>
                    Kies één van de instellingen in de sidebar om uw gegevens te
                    wijzigen.{" "}
                  </p>
                </div>
              </>
            )}
          />
          <Route
            path="/settings/balance"
            exact
            component={Balance}
          />
          <Route path="/settings/delete" exact component={DeleteAccount} />
          <Route
            path="/settings/changepassword"
            exact
            component={ChangePassword}
          />
          <Route path="/settings/changename" exact component={ChangeName} />
          <Route path="/settings/changemail" exact component={ChangeMail} />
          <Route path="/settings/orderhistory" exact component={OrderHistory} />
        </div>
      </div>
    );
  }
}

//React Redux
const mapStateToProps = state => {
  return {
    auth: state.login,
    whitelabel: state.whitelabel
  };
};

export default connect(mapStateToProps)(Settings);
