import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import "./ChangeMail.css";
import axios from "axios";

/**
 * @author Donny Smit
 *
 * Component for changing the user's E-Mail by the user.
 * This E-mail does not change the login e-mail, instead it just changes the e-mail articles are sent to.
 */
class ChangeMail extends Component {
  state = {
    emailForm: {
      newEmail: {
        elementType: "input_2",
        elementConfig: {
          id: "newEmail",
          type: "email",
          placeholder:
            "Begin hier met typen om een nieuw ontvangst e-mailadres in te stellen..."
        },
        value: "",
        label: "Nieuw ontvangst e-mailadres",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      confirmEmail: {
        elementType: "input_2",
        elementConfig: {
          id: "confirmEmail",
          type: "email",
          placeholder: "Bevestig uw nieuw ontvangst e-mailadres"
        },
        value: "",
        label: "Bevestig uw nieuw ontvangst e-mailadres",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    correctConfirm: false
  };

  /**
   * Checks validity of input fields, see validation in state. Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedEmailForm = {
      ...this.state.emailForm
    };
    const updatedFormElement = {
      ...updatedEmailForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedEmailForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedEmailForm) {
      formIsValid = updatedEmailForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ emailForm: updatedEmailForm, formIsValid: formIsValid });
  };

  /**
   * [1/3] Checks if the values are correctly set up before making API calls.
   * @param event
   */
  changeEmailCheckHandler = event => {
    event.preventDefault();
    if (this.state.formIsValid) {
      if (
        this.state.emailForm.confirmEmail.value !==
        this.state.emailForm.newEmail.value
      ) {
        toast.error("De ingevoerde e-mailadressen komen niet overeen.");
      } else if (
        this.props.sendEmailAddress === this.state.emailForm.confirmEmail.value
      ) {
        toast.error("Dit is al uw huidge ontvangstadres.");
      } else {
        this.getAppTokenHandler();
        toast.success("E-mailadres succesvol gewijzigd.");
      }
    }
  };

  /**
   * [2/3] Retrieve the application token used to change the send Email.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    axios.post("auth/token", loginParams).then(response => {
      this.changeEmailHandler(response.data.token);
    });
  };

  /**
   * [3/3] Change the user's send email address
   * @param appToken
   */
  changeEmailHandler = appToken => {
    let body = {
      displayName: this.props.displayName,
      sendEmailAddress: this.state.emailForm.newEmail.value,
      settings: {
        cart: this.props.cart
      }
    };
    axios
      .put("user/crud/" + this.props.userId + "?token=" + appToken, body, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(() => {
        this.props.setSendEmailAddress(this.state.emailForm.newEmail.value);
      });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.emailForm) {
      formElementsArray.push({
        id: key,
        config: this.state.emailForm[key]
      });
    }

    return (
      <div className="ChangeMail">
        <h1>Ontvangst e-mailadres wijzigen</h1>
        <p>
          De door u bestelde artikelen worden per mail naar u verzonden.
          Hieronder kunt u een ander e-mailadres instellen om artikelen op te
          ontvangen <br />
          <b>
            Let op! Het e-mailadres om mee in te loggen blijft hetzelfde, u kunt
            dit niet wijzigen.
          </b>
        </p>
        <p>
          Huidige ontvangst e-mailadres:{" "}
          <strong>{this.props.sendEmailAddress}</strong>
        </p>
        <form onSubmit={this.changeEmailCheckHandler}>
          {formElementsArray.map(formElement => (
            <div key={formElement.id}>
              <Input
                id={formElement.config.elementConfig.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
              <br />
            </div>
          ))}
          <Button id="emailButton" disabled={!this.state.formIsValid}>
            Opslaan
          </Button>
        </form>
      </div>
    );
  }
}

//Redux: https://redux.js.org/
const mapStateToProps = state => {
  return {
    displayName: state.displayName,
    sendEmailAddress: state.sendEmailAddress,
    userId: state.userId,
    logintoken: state.logintoken,
    cart: state.cart
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    setSendEmailAddress: sendEmailAddress =>
      dispatch({ type: "SET_SENDEMAILADDRESS", value: sendEmailAddress })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMail);