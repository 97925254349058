import React, { Component } from "react";
import { connect } from "react-redux";

import Home from "../../components/Home/Home";
import SearchTool from "../../components/UI/SearchTool/SearchTool";

/**
 * @author Jeffrey (Extras)
 * @author Elisa (CSS)
 * @author Donny (Core)
 *
 * Class that "searches" and sends the input to the ArticleViewer.
 */
class ArticleSearch extends Component {
  state = {
    searchForm: {
      searchString: {
        elementType: "searchbar",
        elementConfig: {
          id: "searchBar",
          type: "text",
          placeholder: "Zoek hier naar uw artikel..."
        },
        value: "",
        validation: {
          required: true,
          minLength: 2
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  };

  /**
   * Checks validity of input fields
   */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    return isValid;
  }

  /**
   * Handles everything form related
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedSearchForm = {
      ...this.state.searchForm
    };
    const updatedFormElement = {
      ...updatedSearchForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedSearchForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedSearchForm) {
      formIsValid = updatedSearchForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ searchForm: updatedSearchForm, formIsValid: formIsValid });
  };

  /**
   * Save the searchstring using Redux persist. Checks if the form is valid for onSubmit in searchTool.
   */
  postHandler = (searchString, event) => {
    event.preventDefault();
    this.props.searchString(searchString);
    if (this.state.formIsValid) {
      this.props.onSearch();
    }
  };

  componentDidMount() {
    this.props.onReload();
  }

  componentDidUpdate() {
    if (this.props.srch) {
      this.props.history.push("/articles");
    }
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.searchForm) {
      formElementsArray.push({
        id: key,
        config: this.state.searchForm[key]
      });
    }

    return (
      <>
        {this.props.auth ? (
          formElementsArray.map(formElement => (
            <SearchTool
              id={formElement.config.elementConfig.id}
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={this.state.searchForm.searchString.value}
              valid={!this.state.searchForm.searchString.valid}
              validation={this.state.searchForm.searchString.validation}
              touched={this.state.searchForm.searchString.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              clicked={event =>
                this.postHandler(
                  this.state.searchForm.searchString.value,
                  event
                )
              }
              disabled={!this.state.formIsValid}
              stringAmount={this.state.searchForm.searchString.value.length}
            />
          ))
        ) : (
          <Home {...this.props} />
        )}
      </>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    srch: state.search,
    auth: state.login
  };
};

//Redux: https://redux.js.org
const mapDispatchToProps = dispatch => {
  return {
    searchString: searchString =>
      dispatch({ type: "SEARCH_STRING", value: searchString }),
    onSearch: () => dispatch({ type: "SEARCH_BOOLEAN", value: true }),
    onReload: () => dispatch({ type: "SEARCH_BOOLEAN", value: false })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleSearch);
