import React, { Component } from "react";
import "./Filters.css";
import Input from "../UI/Input/Input";
import { connect } from "react-redux";
import Button from "../../components/UI/Button/Button";
import DatePicker from "react-datepicker";
import Moment from "moment";

import "react-datepicker/dist/react-datepicker-cssmodules.css";

/**
 * @author Donny Smit
 * @author Jeffrey Hendrikse
 *
 * Filters for the search results
 */
class Filters extends Component {
  state = {
    rememberCustomDate: false,
    openCustomDate: false,
    startDate: Moment(this.props.customStartDate).toDate(),
    endDate: Moment(this.props.customEndDate).toDate(),
    filterForm: {
      searchString: {
        elementType: "input_2",
        elementConfig: {
          id: "searchString",
          type: "text",
          placeholder: "Zoekopdracht"
        },
        value: this.props.val,
        label: "U zocht op (min 2 karakters)",
        validation: {
          minLength: 2
        },
        valid: true
      },
      source: {
        elementType: "input_2",
        elementConfig: {
          id: "source",
          type: "text",
          placeholder: "Bijvoorbeeld: Telegraaf"
        },
        value: this.props.pubSource,
        label: "bron (Niet verplicht)",
        validation: {
          minLength: 0
        },
        valid: true
      },
      sortMethod: {
        elementType: "select",
        elementConfig: {
          id: "sortMethod",
          options: [
            { value: "relevance", displayValue: "Relevantie" },
            { value: "newfirst", displayValue: "Nieuwste eerst" },
            { value: "oldfirst", displayValue: "Oudste eerst" },
            {
              value: "publicationalphabetical",
              displayValue: "A-Z op publicatie"
            },
            { value: "titlealphabetical", displayValue: "A-Z op titel" }
          ]
        },
        value: this.props.sortMethod,
        validation: {},
        valid: true
      },
      period: {
        elementType: "select",
        elementConfig: {
          id: "period",
          options: [
            { value: "lastQuarter", displayValue: "Afgelopen 3 maanden" },
            { value: "lastWeek", displayValue: "Afgelopen week" },
            { value: "lastMonth", displayValue: "Afgelopen maand" },
            { value: "all", displayValue: "Alles" },
            { value: "custom", displayValue: "Aangepast" }
          ]
        },
        value: this.props.periodMethod,
        validation: {},
        valid: true
      }
    },
    formIsValid: false,
    error: false
  };

  //Check if the user has custom on.
  componentDidMount() {
    if (this.props.periodMethod === "custom") {
      this.setState({
        openCustomDate: true,
        rememberCustomDate: true,
        formIsValid: true
      });
    }
  }

  /**
   * Checks validity of input fields, see validation in state.
   * Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity.
   * Validates entire form when all fields are valid.
   */
  inputChangedHandler = (event, inputIdentifier) => {
    this.setState({ openCustomDate: false });

    //Checks if custom date should be opened and if it should stay open.
    if (event.target.id === "period" && event.target.value === "custom") {
      this.setState({ openCustomDate: true, rememberCustomDate: true });
    } else if (event.target.id !== "period" && this.state.rememberCustomDate) {
      this.setState({ openCustomDate: true });
    } else if (
      event.target.id === "period" &&
      event.target.value !== "custom"
    ) {
      this.setState({ rememberCustomDate: false });
    }

    const updatedFilterForm = {
      ...this.state.filterForm
    };
    const updatedFormElement = {
      ...updatedFilterForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFilterForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedFilterForm) {
      formIsValid = updatedFilterForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ filterForm: updatedFilterForm, formIsValid: formIsValid });
  };

  /**
   * Is called when filters are applied, sends filter parameters to redux.
   */
  filterHandler = event => {
    event.preventDefault();
    if (this.state.formIsValid) {
      this.props.searchString(this.state.filterForm.searchString.value);
      this.props.sortType(this.state.filterForm.sortMethod.value);
      this.props.periodType(this.state.filterForm.period.value);
      this.props.source(this.state.filterForm.source.value);

      //CUSTOM
      if (this.state.openCustomDate && this.state.startDate && this.state.endDate) {
        this.props.startDate(Moment(this.state.startDate).format("YYYY-MM-DD"));
        this.props.endDate(Moment(this.state.endDate).format("YYYY-MM-DD"));
        window.location.reload();
      }

      window.location.reload();
    }
  };

  //Put selected Start date in state
  handleStartDateChange = date => {
    this.setState({ startDate: date });
  };

  //Put selected End date in state
  handleEndDateChange = date => {
    this.setState({ endDate: date });
  };

  render() {
    //The 2 datepickers displayed when clicked on custom.
    let customDateFilters = (
      <>
        <label>Van </label>
        <br />
        <DatePicker
          id="startDate"
          selected={this.state.startDate}
          dateFormat="yyyy-MM-dd"
          selectsStart
          onChange={this.handleStartDateChange}
          maxDate={this.state.endDate}
        />{" "}
        <br />
        <label>Tot </label>
        <br />
        <DatePicker
          id="endDate"
          selected={this.state.endDate}
          dateFormat="yyyy-MM-dd"
          selectsEnd
          minDate={this.state.startDate}
          onChange={this.handleEndDateChange}
        />
      </>
    );

    //Transforms loginForm to array for mapping later in return function
    const formElementsArray = [];
    for (let key in this.state.filterForm) {
      formElementsArray.push({
        id: key,
        config: this.state.filterForm[key]
      });
    }

    return (
      <div className="Filters">
        <h2>Filters</h2>
        <form onSubmit={() => this.filterHandler()}>
          {formElementsArray.map(formElement => (
            <Input
              id={formElement.config.elementConfig.id}
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
            />
          ))}
          {this.state.openCustomDate ? customDateFilters : null}
          <Button id="filterButton" clicked={this.filterHandler}>
            Filters toepassen
          </Button>
        </form>
      </div>
    );
  }
}

//Map state.search to props.srch (Redux)
const mapStateToProps = state => {
  return {
    val: state.searchString,
    sortMethod: state.sortType,
    periodMethod: state.periodType,
    customStartDate: state.startDate,
    customEndDate: state.endDate,
    pubSource: state.source
  };
};

//Redux: https://redux.js.org
const mapDispatchToProps = dispatch => {
  return {
    searchString: searchString =>
      dispatch({ type: "SEARCH_STRING", value: searchString }),
    sortType: sortType => dispatch({ type: "SORT_TYPE", value: sortType }),
    periodType: periodType =>
      dispatch({ type: "PERIOD_TYPE", value: periodType }),
    startDate: startDate => dispatch({ type: "START_DATE", value: startDate }),
    endDate: endDate => dispatch({ type: "END_DATE", value: endDate }),
    source: source => dispatch({ type: "SOURCE", value: source })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
