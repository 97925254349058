import React, { Component } from "react";
import { connect } from "react-redux";

import Timer from "../UI/Timer/Timer";
import "./Logout.css";

/**
 * @author Donny Smit
 *
 * Component for logging the user out
 */
class Logout extends Component {

  /**
   * calls redux to set authenticated state to false
   */
  componentWillMount() {
    if ((this.props.auth && this.props.whitelabel) || !this.props.auth) {
      this.props.history.push("/");
    }
    else {
      this.props.onLogout();
    }
  }

  render() {
    return (
      <>
        <div className="Logout">
          <h2>U bent nu uitgelogd!</h2>
          <div className="LogoutTime">
            U wordt over <Timer {...this.props} /> seconden automatisch naar de
            homepage gebracht.
          </div>
          <br />
        </div>
      </>
    );
  }
}

//Redux: https://redux.js.org/
const mapStateToProps = state => {
  return {
    auth: state.login,
    whitelabel: state.whitelabel
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch({ type: "IS_AUTHENTICATED", value: false }),
    onWhitelabelLogout: () => dispatch({ type: "IS_WHITELABEL", value: false })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
