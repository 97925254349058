import React from "react";

import "./FooterItems.css";
import FooterItem from "./FooterItem/FooterItem";

/**
 * @author Elisa Straatman
 *
 * List of footer items to be shown in the footer
 */
const footerItems = () => (
  <ul className="FooterItems">
    <FooterItem id="about" link="/about">
      Over Artikelgemist.nl
    </FooterItem>
    <FooterItem id="termsOfAgreement" link="/terms">
      Algemene voorwaarden
    </FooterItem>
    <FooterItem id="privacyStatement" link="/privacy">
      Privacy
    </FooterItem>
    <FooterItem id="media" link="/media">
      Media
    </FooterItem>
    <FooterItem id="contact" link="/contact">
      Contact
    </FooterItem>
    <FooterItem id="homeFooter" link="/">
      artikelgemist.nl © 2019
    </FooterItem>
  </ul>
);

export default footerItems;
