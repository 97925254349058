import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  FaShoppingCart,
  FaSignOutAlt,
  FaHome,
  FaUserLock,
  FaUserPlus,
  FaAt,
  FaUserTag,
  FaKey,
  FaBan,
  FaEuroSign,
  FaHistory
} from "react-icons/fa";
import { php } from "locutus";

import "./SideDrawerNavigation.css";
import Logo from "../../../Logo/Logo";

const { number_format } = php.strings;

/**
 * @author Elisa Straatman
 *
 * Component for the navigation links in the side drawer menu
 */

class SideDrawerNavigation extends Component {
  render() {
    return (
      <div className="SideDrawerNavigation">
        <div className="NavigationLogo">
          <Logo id="navigationLogo" />
        </div>
        <NavLink
          id="sideDrawerHome"
          onClick={this.props.clicked}
          to={{
            pathname: "/"
          }}
        >
          <FaHome /> Home
        </NavLink>
        <br />
        <br />

        {!this.props.auth ? (
          <>
            <NavLink
              id="sideDrawerLogin"
              onClick={this.props.clicked}
              to={{
                pathname: "/login"
              }}
            >
              <FaUserLock /> Inloggen
            </NavLink>
            <br />
            <br />
            <NavLink
              id="sideDrawerRegister"
              onClick={this.props.clicked}
              to={{
                pathname: "/register"
              }}
            >
              <FaUserPlus /> Registreren
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/cart"
              }}
            >
              <FaShoppingCart /> Winkelwagentje
            </NavLink>
            <hr />
            {!this.props.whitelabel ? 
            <>
            <p>Huidig saldo: € {number_format(this.props.balance, 2, ",", ".")}</p>
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/balance"
              }}
            >
              <li>
                <FaEuroSign /> Saldo opwaarderen
              </li>
            </NavLink>
            <br />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/orderhistory"
              }}
            >
              <li>
                <FaHistory /> Orderhistorie
              </li>
            </NavLink>
            <br />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/changename"
              }}
            >
              <li>
                <FaUserTag /> Schermnaam instellen
              </li>
            </NavLink>
            <br />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/changemail"
              }}
            >
              <li>
                <FaAt /> Ontvangstadres instellen
              </li>
            </NavLink>
            <br />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/changepassword"
              }}
            >
              <li>
                <FaKey /> Wachtwoord wijzigen
              </li>
            </NavLink>
            <br />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/delete"
              }}
            >
              <li>
                <FaBan /> Account verwijderen
              </li>
            </NavLink>
            <hr />
            <NavLink
              id="sideDrawerCart"
              onClick={this.props.clicked}
              to={{
                pathname: "/settings/logout"
              }}
            >
              <li>
                <FaSignOutAlt /> Uitloggen
              </li>
            </NavLink>
            </> : null}
            <br />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.login,
    art: state.articles,
    balance: state.balance,
    whitelabel: state.whitelabel
  };
};

export default connect(mapStateToProps)(SideDrawerNavigation);
