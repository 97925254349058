import React from "react";
import { php } from "locutus";
import { FaPlus } from "react-icons/fa";

import "./Article.css";

const { number_format } = php.strings;

/**
 * @author Elisa Straatman
 *
 *  This is the layout for a single article as it is rendered in the article viewer
 */
const article = props => (
  <div id={props.id} className="Article">
    <img src={props.imageUrl} alt="" className="ArticleImg" />

    <div className="ArticleInfo">
      <div className="ArticleHeader">
        <h2>{props.headline}</h2>
        <p>€ {number_format(props.price, 2, ",", ".")}</p>
      </div>

      {/*Removes the <###> tags that Mediaweb includes in the text*/}
      <div className="ArticleSummary">
        {props.summary.replace(/<###>|<\/###>/g, "")}
      </div>
      <div className="ArticlePublication">
        <div className="ArticlePublicationName"><strong>Bron: {props.pubName} </strong></div>
        <div className="ArticlePublicationDate">
          <i>Publicatiedatum: {props.pubDate}{" "}</i>
        </div>

        <button
          id="articleButton"
          className="ArticleButton"
          onClick={props.clicked}
          disabled={props.disabled}
        >
          Toevoegen <FaPlus />
        </button>
      </div>
      <div className="ArticleStripe" />
    </div>

    <button
      id="articleButtonMobile"
      className="ArticleButtonMobile"
      onClick={props.clicked}
    >
      <FaPlus />
    </button>
  </div>
);

export default article;
