import React from "react";
import { FaSearch } from "react-icons/fa";

import "./SearchTool.css";
import Input from "../Input/Input";

/**
 * @author Elisa Straatman
 *
 * The searchtool component that gets displayed as homepage when the user is logged in.
 */
const searchTool = props => (
  <div className="Search">
    <div className="SearchTool">
      <h1>Op zoek naar een artikel uit de media?</h1>
      <p>
        Artikelgemist.nl biedt u een eenvoudige manier om artikelen terug te
        vinden.
      </p>
      <div className="InputWrapper">
        <button
          id="searchButton"
          disabled={props.disabled}
          onClick={props.clicked}
        >
          <FaSearch />
        </button>
        <form onSubmit={props.clicked}>
          <Input
            elementType={props.elementType}
            elementConfig={props.elementConfig}
            value={props.value}
            invalid={props.valid}
            shouldValidate={props.validation}
            touched={props.touched}
            changed={props.changed}
          />
        </form>
      </div>
    </div>
    {props.stringAmount < 2 ? (
      <h3>U moet minimaal 2 letters invullen voordat u kan zoeken.</h3>
    ) : null}
  </div>
);

export default searchTool;
