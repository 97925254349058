import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import "./Balance.css";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import { toast } from "react-toastify";
import { php } from "locutus";

const { number_format } = php.strings;

/**
 * @author Donny Smit
 *
 * Component that displays the saldo information of the user.
 */
class Balance extends Component {
  state = {
    saldoForm: {
      saldoValue: {
        elementType: "input_2",
        elementConfig: {
          id: "source",
          type: "number",
          "data-number-to-fixed": "2",
          placeholder: "Vul hier uw gewenste bedrag in."
        },
        label: "+ €0,29 transactiekosten",
        value: 0,
        validation: {
          minLength: 1,
          required: true
        },
        valid: true
      }
    },
    error: false
  };

  /**
   * [1/3]
   * @param event
   */
  saldoCheckHandler = async event => {
    event.preventDefault();
    if (this.state.formIsValid) {
      if (this.state.saldoForm.saldoValue.value >= 1) {
        this.getAppTokenHandler();
      } else {
        toast.error("Vul een correct bedrag in.");
      }
    }
  };

  /**
   * [2/3] Retrieve the application token.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    axios.post("auth/token", loginParams).then(response => {
      this.buySaldoHandler(response.data.token);
    });
  };

  /**
   * [3/3] Buy the saldo using the application token.
   * @param appToken
   */
  buySaldoHandler = appToken => {
    let body = {
      amount: +this.state.saldoForm.saldoValue.value,
      description: "Artikelgemist - Saldo opwaarderen"
    };
    axios
      .post("payment/crud?token=" + appToken, body, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        window.open(response.data.mollieUrl, "_blank");
        window.close();
      });
  };

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    this.setState({ error: false });

    const updatedSaldoForm = { ...this.state.saldoForm };
    const updatedFormElement = { ...updatedSaldoForm[inputIdentifier] };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedSaldoForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedSaldoForm) {
      formIsValid = updatedSaldoForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ saldoForm: updatedSaldoForm, formIsValid: formIsValid });
  };

  /**
   * Checks validity of input fields, see validation in state.
   */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    return isValid;
  }

  render() {
    //Transforms loginForm to array for mapping later in return function
    const formElementsArray = [];
    for (let key in this.state.saldoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.saldoForm[key]
      });
    }

    return (
      <div className="Balance">
        <h1>Informatie over uw saldo</h1>
        <p>
          Hieronder vindt u alle informatie over uw huidige saldo. Om uw saldo
          op te waarderen, kiest u een bedrag en klikt u op de knop
          "Opwaarderen". Hierna wordt u doorgeleid naar de betaalomgeving.
          Het minimale bedrag waarmee u kan opwaarderen is €1,00.
        </p>
        <p>
          <b>Uw huidige saldo:</b> €{number_format(this.props.balance, 2, ",", ".")}
        </p>

        <form onSubmit={this.saldoCheckHandler}>
          {formElementsArray.map(formElement => (
            <Input
              id={formElement.config.elementConfig.id}
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
            />
          ))}
          <Button id="saldoButton" clicked={this.saldoCheckHandler}>
            Opwaarderen
          </Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logintoken: state.logintoken,
    balance: state.balance
  };
};

export default connect(mapStateToProps)(Balance);