import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItem.css";

/**
 * @author Elisa Straatman
 *
 * Simple component for navigation items
 */
const navigationItem = props => (
  <li className="NavigationItem">
    <NavLink
      id={props.id}
      strict
      to={props.link}
      className={props.active ? classes.active : null}
    >
      {props.children}
    </NavLink>
  </li>
);

export default navigationItem;
