import React from "react";

import "./Footer.css";
import FooterItems from "../Footer/FooterItems/FooterItems";

/**
 * @author Elisa Straatman
 *
 * Footer container with footer items
 */
const footer = props => (
  <div className="Footer">
    <FooterItems />
  </div>
);

export default footer;
