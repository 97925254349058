import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import axios from "axios";

import reducer from "./store/reducers/reducer";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

axios.defaults.baseURL = "https://api.artikelgemist.nl/v2/";

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

let hostname = window.location.hostname.split(".");

//Check if we are on whitelabel
if (
  (hostname.length >= 4 && hostname[0] === "www") ||
  (hostname.length === 3 && hostname[0] !== "www")
) {
  let params = new URLSearchParams();
  params.append(
    "username",
    hostname.length >= 3 && hostname[0] !== "www" ? hostname[0] : hostname[1]
  );
  params.append("password", "ArtikelGemist");
  axios
    .post("auth/login", params)
    .then(response => {
      store.dispatch({ type: "SET_WHITELABELHOST", value: hostname.length >= 3 && hostname[0] !== "www" ? hostname[0] : hostname[1] });
      store.dispatch({ type: "IS_WHITELABEL", value: true });
      store.dispatch({ type: "SET_LOGINTOKEN", value: response.data.token });
      store.dispatch({ type: "IS_AUTHENTICATED", value: true });
      store.dispatch({ type: "SET_CART", value: [] });
    })
    .catch(() => {
      window.location.replace("https://artikelgemist.nl");
    });
}

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
