import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";

import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import "./SetPassword.css";
import ErrorMessage from "../UI/ErrorMessage/ErrorMessage";

/**
 * @author Elisa Straatman
 * Layout
 * @author Jeffrey Hendrikse
 * Rest
 *
 * Component for setting a password for a logged out user.
 */
class SetPassword extends Component {
    state = {
        username: "",
        resetToken: "",
        setPasswordForm: {
            password: {
                elementType: "input_2",
                elementConfig: {
                    id: "password",
                    type: "password",
                    placeholder: "Kies een wachtwoord"
                },
                value: "",
                label: "Wachtwoord instellen",
                validation: {
                    required: true,
                    strengthTest: true
                }
            },
            passwordRepeat: {
                elementType: "input_2",
                elementConfig: {
                    id: "passwordRepeat",
                    type: "password",
                    placeholder: "Herhaal uw wachtwoord"
                },
                value: "",
                label: "Wachtwoord herhalen",
                validation: {
                    required: true
                },

                valid: false,
                touched: false
            }
        }
    };

    componentWillMount() {
        if (this.props.auth) {
            this.props.history.goBack();
        }
        if (!this.props.location.search) {
            this.props.history.push("/");
        }
    }

    /**
     * Retrieves search params from the email that is sent to users and stores the username and resetToken
     */
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        for (let param of query.entries()) {
            if (param[0] === "username") {
                this.setState({username: param[1]});
            }
            if (param[0] === "token") {
                this.setState({resetToken: param[1]});
            }
        }
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    /**
     * Checks validity of input fields, see validation in state. Returns boolean.
     */
    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        //Password has to contain at least {1 lowercase, 1 UPPERCASE, 1 number, 1 special character, min of 8 characters long}
        if (rules.strengthTest) {
            const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            isValid = regex.test(value);
        }
        return isValid;
    }

    /**
     * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
     */
    inputChangedHandler = (event, inputIdentifier) => {
        const updatedsetPasswordForm = {
            ...this.state.setPasswordForm
        };
        const updatedFormElement = {
            ...updatedsetPasswordForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        updatedFormElement.touched = true;
        updatedsetPasswordForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedsetPasswordForm) {
            formIsValid =
                updatedsetPasswordForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({
            setPasswordForm: updatedsetPasswordForm,
            formIsValid: formIsValid
        });
    };

    /**
     * Checks if entered passwords are the same, if not throws error message.
     * If passwords are the same, send password and token to the API and show success message.
     */
    changePasswordHandler = event => {
        event.preventDefault();
        if (!this.state.formIsValid) {
            return toast.error("Uw wachtwoord moet bestaan uit minimaal 1 hoofdletter, 1 cijfer, 1 leesteken en moet minimaal 8 karakters lang zijn.",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
        }
        if (
            this.state.setPasswordForm.password.value ===
            this.state.setPasswordForm.passwordRepeat.value
        ) {
            let params = new URLSearchParams();
            params.append("token", this.state.resetToken);
            params.append("password", this.state.setPasswordForm.password.value);

            axios
                .post("auth/reset_password", params)
                .then(() => {
                    this.props.history.push("/login");
                });
        } else {
            toast.error(
                <p id="passwordError">
                    De ingevulde wachtwoorden komen niet overeen
                </p>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                }
            )
        }
    };

    render() {
        //Transforms setPasswordForm to array for mapping later in return function
        const formElementsArray = [];
        for (let key in this.state.setPasswordForm) {
            formElementsArray.push({
                id: key,
                config: this.state.setPasswordForm[key]
            });
        }

        let passwordError = <ErrorMessage>De opgegeven wachtwoorden komen niet overeen.</ErrorMessage>;
        let passwordSucces = (
            <p>
                Uw wachtwoord is succesvol gewijzigd. U kunt nu met uw nieuwe wachtwoord
                inloggen
            </p>
        );

        return (
            <div className="SetPasswordContent">
                <div className="SetPasswordBox">
                    <h1>Wachtwoord instellen</h1>
                    <h5>
                        Hieronder kunt u uw wachtwoord instellen. Deze moet bestaan uit
                        minimaal 1 hoofdletter, 1 cijfer, 1 leesteken en moet minimaal 8
                        karakters lang zijn.
                    </h5>
                    {this.state.passwordError ? passwordError : null}
                    {this.state.passwordSucces ? passwordSucces : null}
                    <form onSubmit={() => this.changePasswordHandler}>
                        {formElementsArray.map(formElement => (
                            <Input
                                id={formElement.config.elementConfig.id}
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={event =>
                                    this.inputChangedHandler(event, formElement.id)
                                }
                                label={formElement.config.label}
                            />
                        ))}
                        <Button id="sendButton" clicked={this.changePasswordHandler}>
                            Verstuur
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

//React Redux
const mapStateToProps = state => {
    return {
        auth: state.login
    };
};

export default connect(mapStateToProps)(SetPassword);
