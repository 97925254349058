import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import AGLogo from "../../assets/img/ag_logo.png";
import "./Logo.css";

/**
 * @author Elisa Straatman
 *
 * Simple container for the logo
 */
class Logo extends Component {
  render() {
    const whitelabelLogo =
      this.props.whitelabelHost === undefined
        ? AGLogo
        : require("../../whitelabel/" + this.props.whitelabelHost + ".png");

    return (
      <div className="Logo">
        <NavLink id="logoHome" to="/">
          {" "}
          {this.props.whitelabel ? (
            <img
              src={whitelabelLogo}
              alt="Whitelabel Logo"
              id="whitelabelLogo"
            />
          ) : (
            <img src={AGLogo} alt="Logo Artikelgemist" id="AGLogo" />
          )}
        </NavLink>
      </div>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    whitelabelHost: state.whitelabelHost,
    whitelabel: state.whitelabel
  };
};

export default connect(mapStateToProps)(Logo);
