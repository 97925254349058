import React, { Component } from "react";
import { connect } from "react-redux";
import { FaFilter } from "react-icons/fa";
import Moment from "moment";

import "./ArticleViewer.css";
import Article from "../../components/Article/Article";
import Filters from "../../components/Filters/Filters";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/UI/Button/Button";
import DrawerToggle from "../../components/Navigation/SideDrawer/DrawerToggle/DrawerToggle";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";

import { toast } from "react-toastify";

/**
 * @author Donny (Core)
 * @author Jeffrey (Extras)
 * @author Elisa (CSS)
 *
 * Class that displays articles on the articleviewer page.
 */
class ArticleViewer extends Component {
  _isMounted = false;

  state = {
    selectedArticle: [],
    articles: [],
    error: false,
    loading: true,
    modal: false,
    count: 0,
    offset: 0,
    loadingMore: false,
    showSideDrawer: false,
    addArticle: false
  };

  // Criteria that have to be met when reaching ArticleViewer.
  componentDidMount() {
    this._isMounted = true;
    if (this.props.srch && this.props.auth) {
      this.articleGetHandler();
    } else {
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
   * Increases offset for the search API call to display more articles.
   */
  searchMoreHandler = () => {
    this.setState({ loadingMore: true });
    let newOffset = this.state.offset + 20;
    this.setState({ offset: newOffset });
    this.articleGetHandler();
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  /**
   * Retrieve the application token.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    return axios.post("auth/token", loginParams);
  };

  /*
   * Get all articles based on the user's input.
   */
  articleGetHandler = () => {
    this.getAppTokenHandler().then(tokenResponse => {
      let searchParam = {
        matchText: this.props.val,
        periodType: this.props.periodType,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        minRelevance: 50,
        sortingType: this.props.sortType,
        sources: this.props.pubSource
      };
      axios
        .post(
          "search?token=" +
            tokenResponse.data.token +
            "&offset=" +
            this.state.offset +
            "&limit=" +
            20,
          searchParam,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(searchResponse => {
          if (this._isMounted) {
            this.setState({
              count: searchResponse.data.count,
              appToken: tokenResponse.data.token
            });
            this.getPriceHandler(
              tokenResponse.data.token,
              searchResponse.data.items
            );
          }
        });
    });
  };

  /**
   * Get auto generated prices from API and merge those with articles array.
   * @param appToken the required appToken.
   * @param nextArticles the articles from the search result.
   */
  getPriceHandler = (appToken, nextArticles) => {
    Promise.all(
      nextArticles.map(article =>
        axios.get(
          "search/articleprice/" + article.articleId + "?token=" + appToken
        )
      )
    ).then(priceResponses => {
      let articleDetails = priceResponses.map(priceResponse => ({
        price: priceResponse.data.price
      }));
      const articles = nextArticles.map((article, i) => ({
        ...article,
        ...articleDetails[i]
      }));
      if (this._isMounted) {
        this.setState({
          articles: [...this.state.articles, ...articles],
          loading: false,
          loadingMore: false
        });
      }
    });
  };

  /**
   * Add article to User Settings in DB
   * @param article the single article that we are adding to the cart.
   * @param token the required appToken.
   */
  AddArticleToDBHandler = (article, token) => {
    let body = {
      sendEmailAddress: this.props.sendEmailAddress,
      displayName: this.props.displayName,
      settings: {
        cart: [...this.props.cart, ...article]
      }
    };
    axios
      .put("user/crud/" + this.props.userId + "?token=" + token, body, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(() => {
        this.setState({ addArticle: false });
      });
    this.props.addArticleCart(article);
  };

  modalClosedHandler = () => {
    this.setState({ modal: false });
  };

  /**
   * Find the selected article by the user and add it in the cart.
   * @param id the articleId.
   */
  AddArticleToCartHandler = id => {
    if (this.props.whitelabel) {
      this.setState({ modal: true });
    } 
    else {
      this.setState({ addArticle: true });
      let oneArticle = this.state.articles.filter(article => {
        return article.articleId === id;
      });
      this.getAppTokenHandler().then(tokenResponse => {
        axios.get("user/crud?token=" + tokenResponse.data.token).then(res => {
          let index = res.data.settings.cart.findIndex(article => {
            return article.articleId === id;
          });

          //Checks if the article isn't added to the cart already.
          if (this._isMounted) {
            if (index === -1) {
              toast.success("Artikel toegevoegd aan winkelmand!");
              this.AddArticleToDBHandler(oneArticle, tokenResponse.data.token);
            } else {
              this.setState({ addArticle: false });
              this.setState({ modal: true });
            }
          }
        });
      });
    }
  };

  render() {
    let articles = this.state.articles.map(article => {
      return (
        <Article
          id={article.articleId}
          key={article.articleId}
          clicked={() => this.AddArticleToCartHandler(article.articleId)}
          disabled={this.state.addArticle}
          price={article.price}
          headline={article.headline}
          publisher={article.publisher}
          pubType={article.publicationType}
          pubDate={Moment(article.publicationDate).format("DD-MM-YYYY")}
          summary={article.summary.replace(/<###>|<\/###>/g, "")}
          pubName={article.publicationName}
          imageUrl={article.imageUrl}
        />
      );
    });

    /**
     * Show the amount of results found in text
     */
    let searchResultsText = (
      <h2 id="searchResultTotal">
        {articles.length} van {this.state.count === -1 ? 0 : this.state.count}{" "}
        zoekresultaten weergegeven
      </h2>
    );

    let noResultsFoundText = (
      <h2 id="noResultsText">Er zijn geen resultaten gevonden.</h2>
    );

    let modalCart = (
      this.props.whitelabel ? 
      <Modal
        id="inCartText"
        show={this.state.modal}
        modalClosed={this.modalClosedHandler}
      >
        <h3>Oeps!</h3>
        <p>Om een artikel aan uw winkelwagentje toe te voegen moet u naar de algemene website gaan!</p>
        <Button clicked={() => window.location.replace("https://artikelgemist.nl")}>
          Ga naar de algemene website
        </Button>
      </Modal>
      : <Modal
        id="inCartText"
        show={this.state.modal}
        modalClosed={this.modalClosedHandler}
      >
        <h3>Oeps!</h3>
        <p>Dit artikel staat al in uw winkelwagentje!</p>
      </Modal>
    );

    let drawerToggle = (
      <div className="DrawerToggleFilters">
        <DrawerToggle clicked={this.sideDrawerToggleHandler}>
          {" "}
          <FaFilter /> Toon filters{" "}
        </DrawerToggle>
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        >
          <Filters {...this.props} />
        </SideDrawer>
      </div>
    );

    let loadMoreButton = (
      <Button
        id="searchMoreButton"
        disabled={this.state.loadingMore}
        clicked={this.searchMoreHandler}
      >
        Meer zoeken...
      </Button>
    );

    if (this.state.loading) {
      articles = <Spinner />;
      searchResultsText = null;
      drawerToggle = null;
    }

    if (this.state.loadingMore) {
      loadMoreButton = (
        <>
          <div className="LoadingMoreSpinner">
            <Spinner />
          </div>
        </>
      );
    }

    return (
      <>
        {modalCart}
        <div className="ArticleViewer">
          {this.props.srch ? searchResultsText : null}
          {drawerToggle}
          <hr />
          {this.props.srch ? articles : null} <br />
          {!this.state.loading
            ? articles.length === this.state.count || this.state.count <= 0
              ? this.state.count <= 0
                ? noResultsFoundText
                : null
              : loadMoreButton
            : null}
        </div>
        <div className="Test">
          <Filters {...this.props} />
        </div>
      </>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    srch: state.search,
    auth: state.login,
    art: state.articles,
    val: state.searchString,
    logintoken: state.logintoken,
    sortType: state.sortType,
    periodType: state.periodType,
    startDate: state.startDate,
    endDate: state.endDate,
    pubSource: state.source,
    userId: state.userId,
    cart: state.cart,
    displayName: state.displayName,
    sendEmailAddress: state.sendEmailAddress,
    whitelabel: state.whitelabel
  };
};

//Redux: https://redux.js.org
const mapDispatchToProps = dispatch => {
  return {
    addArticleCart: oneArticle =>
      dispatch({ type: "ADD_TO_CART", payload: oneArticle })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleViewer);
