import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Button from "../UI/Button/Button";

import "./Order.css";

/**
 * @author Donny Smit
 *
 * Order page where the user will be redirected to when they finished a payment.
 */
class Order extends Component {
  _isMounted = false;

  state = {
    isPaid: false,
    orderStatus: "Uw betaling wordt verwerkt, sluit deze pagina niet.."
  };

  componentDidMount() {
    if (!this.props.auth) {
      this.props.history.push("/");
    }
    this.getAppTokenHandler();
    this._isMounted = true;
    this.timer = setInterval(() => this.getOrderHandler(), 5000); //5 sec
    this.token = setInterval(() => this.getAppTokenHandler(), 900000); //900 sec
  }

  

  //Memory leak fixes
  componentWillUnmount() {
    this._isMounted = false;
    this.clearTimers();
  }

  /**
   * Retrieve the application token.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    axios.post("auth/token", loginParams).then(res => {
      this.setState({ appToken: res.data.token });
    });
  };

  /**
   * Purchases the articles
   * @param i the index of the articles.
   * @param token the required app token.
   */
  purchaseArticles = (i) => {
    axios.post("purchase/crud/" + this.props.cart[i].articleId + "?token=" + this.state.appToken);
  }

  /**
   * Retrieve the order
   */
  getOrderHandler = () => {
    axios
      .get(
        "payment/status/" +
          this.props.match.params.id +
          "?token=" +
          this.state.appToken
      )
      .then(res => {
        if (res.data.status !== "open") {
          this.clearTimers();
        }
        if (this._isMounted) {
          this.setState({ isPaid: true })
          this.setState({ orderDescription: res.data.description });

          //All status cases for the orders
          if (res.data.status === "paid") {
            this.setState({
              orderStatus:
                "Uw betaling " + this.props.match.params.id + " is geslaagd!",
                isPaid: false
            });

            //If article is paid
            if (res.data.description.toLowerCase().includes("artikelen")) {
              for (let i = 0; i < this.props.cart.length; i++) {
                this.purchaseArticles(i);
              }
              this.clearCartHandler();
              this.props.setCart([]);
            }

            //If balance is paid
            if (res.data.description.toLowerCase().includes("saldo")) {
              this.props.setBalance(this.props.balance + res.data.amount - this.props.transactionCostIdeal);
            }
          }
          if (res.data.status === "failed") {
            this.setState({
              orderStatus: "Er is iets fout gegaan met uw betaling!"
            });
          }
          if (res.data.status === "canceled") {
            this.setState({ orderStatus: "Uw betaling is geannuleerd!" });
          }
          if (res.data.status === "expired") {
            this.setState({ orderStatus: "Uw betaling is verlopen!" });
          }
          if (res.data.status === "open") {
            this.setState({ orderStatus: "Uw betaling is bezig met verwerken..." });
          }
        }
      });
  };

  /** 
   * Clears the token and API Call timers.
   */
  clearTimers = () => {
    clearInterval(this.timer);
    clearInterval(this.token);
    this.timer = null;
    this.token = null;
  }

  /**
   * Clears the cart articles.
   */
  clearCartHandler = () => {
    let body = {
      sendEmailAddress: this.props.sendEmailAddress,
      displayName: this.props.displayName,
      settings: {
        cart: []
      }
    };
    axios.put(
      "user/crud/" + this.props.userId + "?token=" + this.state.appToken,
      body,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  };

  render() {

    let backToCart = (
      <Button clicked={() => this.props.history.push("/cart")}>Ga terug naar uw winkelwagen</Button>
    );

    return (
      <div className="Order">
        <div className="OrderBox">
          <h2>{this.state.orderStatus}</h2>
          <p>{this.state.orderDescription}</p>
          {!this.state.isPaid ? null : backToCart}
        </div>
      </div>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    displayName: state.displayName,
    sendEmailAddress: state.sendEmailAddress,
    balance: state.balance,
    logintoken: state.logintoken,
    userId: state.userId,
    cart: state.cart,
    auth: state.login,
    transactionCostIdeal: state.transactionCostIdeal
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    setBalance: balance => dispatch({ type: "SET_BALANCE", value: balance }),
    setCart: cart => dispatch({ type: "SET_CART", value: cart })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);
