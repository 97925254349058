import React from "react";
import { NavLink } from "react-router-dom";

import "./FooterItem.css";

/**
 * @author Elisa Straatman
 *
 * Layout for a footer item
 */
const footerItem = props => (
  <li className="FooterItem">
    <NavLink id={props.id} to={props.link}>
      {props.children}
    </NavLink>
  </li>
);

export default footerItem;
