import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import "./ChangeName.css";

/**
 * @author Donny Smit
 *
 * Component for changing the name of an user by an user.
 */
class ChangeName extends Component {
  state = {
    nameForm: {
      newName: {
        elementType: "input_2",
        elementConfig: {
          id: "newName",
          type: "text",
          placeholder: "Begin hier met typen om een schermnaam in te stellen..."
        },
        value: "",
        label: "Nieuwe schermnaam instellen",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  };

  /**
   * Checks validity of input fields, see validation in state. Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    return isValid;
  }

  /**
   * [1/2] Retrieve the application token used to change the display name.
   * @param event
   */
  getAppTokenHandler = event => {
    event.preventDefault();
    if (this.state.formIsValid) {
      let loginParams = new URLSearchParams();
      loginParams.append("token", this.props.logintoken);
      axios.post("auth/token", loginParams).then(response => {
        this.nameChangeHandler(response.data.token);
      });
    }
  };

  /**
   * [2/2] Change the user's display name
   * @param appToken
   */
  nameChangeHandler = appToken => {
    let body = {
      sendEmailAddress: this.props.sendEmailAddress,
      displayName: this.state.nameForm.newName.value,
      settings: {
        cart: this.props.cart
      }
    };
    axios
      .put("user/crud/" + this.props.userId + "?token=" + appToken, body, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        this.props.setDisplayName(this.state.nameForm.newName.value);
      });
  };

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedNameForm = {
      ...this.state.nameForm
    };
    const updatedFormElement = {
      ...updatedNameForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedNameForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedNameForm) {
      formIsValid = updatedNameForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ nameForm: updatedNameForm, formIsValid: formIsValid });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.nameForm) {
      formElementsArray.push({
        id: key,
        config: this.state.nameForm[key]
      });
    }

    return (
      <div className="ChangeName">
        <h1>Schermnaam wijzigen</h1>
        <p>
          De door u bestelde artikelen worden per mail naar u verzonden. Door
          een schermnaam in te stellen beginnen deze e-mails met een
          persoonlijke aanhef. Een schermnaam instellen is niet verplicht.
        </p>
        <p>
          Huidige schermnaam: <strong>{this.props.displayName}</strong>
        </p>
        <form onSubmit={this.getAppTokenHandler}>
          {formElementsArray.map(formElement => (
            <div key={formElement.id}>
              <Input
                id={formElement.config.elementConfig.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
              <br />
            </div>
          ))}
          <Button
            id="nameButton"
            clicked={this.getAppTokenHandler}
            disabled={!this.state.formIsValid}
          >
            Opslaan
          </Button>
        </form>
      </div>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    sendEmailAddress: state.sendEmailAddress,
    logintoken: state.logintoken,
    displayName: state.displayName,
    userId: state.userId,
    cart: state.cart
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDisplayName: displayName =>
      dispatch({ type: "SET_DISPLAYNAME", value: displayName })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeName);