import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaCog,
  FaEuroSign,
  FaAt,
  FaUserCog,
  FaUserTimes,
  FaHistory
} from "react-icons/fa";

import "./SettingsNavigation.css";

/**
 * @author Donny Smit
 *
 * Component for the navigation links in the settings menu
 */
const settingsNavigation = props => (
  <div className="SettingsNavigation">
    <NavLink
      id="updateBalance"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/balance"
      }}
    >
      {" "}
      <FaEuroSign className="Icon" /> Saldo opwaarderen
    </NavLink>
    <br />
    <NavLink
      id="orderHistory"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/orderhistory"
      }}
    >
      {" "}
      <FaHistory className="Icon" /> Orderhistorie
    </NavLink>
    <NavLink
      id="changeName"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/changename"
      }}
    >
      {" "}
      <FaUserCog className="Icon" /> Schermnaam wijzigen
    </NavLink>
    <br />
    <NavLink
      id="changeEmail"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/changemail"
      }}
    >
      {" "}
      <FaAt className="Icon" /> Ontvangstadres wijzigen
    </NavLink>
    <br />
    <NavLink
      id="changePassword"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/changepassword"
      }}
    >
      {" "}
      <FaCog className="Icon" /> Wachtwoord wijzigen
    </NavLink>
    <br />
    <NavLink
      id="delete"
      onClick={props.clicked}
      to={{
        pathname: props.match.path + "/delete"
      }}
    >
      {" "}
      <FaUserTimes className="Icon" /> Account verwijderen
    </NavLink>
  </div>
);

export default settingsNavigation;
