import React from "react";

import "./Legal.css";

/**
 * @author Elisa Straatman
 * Legal documents
 */
const privacyStatement = () => (
  <div className="Legal" id="privacyStatement">
    <h1>Privacyverklaring Artikelgemist.nl</h1>
    <h4>Verantwoordelijke entiteit</h4>
    <p>
      Dit is de Privacyverklaring van Artikelgemist.nl. Artikelgemist.nl is een
      artikelnabesteldienst voor een diversiteit aan aangesloten uitgevers.
      Artikelgemist.nl is een webactiviteit van Profactys BV. Profactys is
      statutair gevestigd aan Randstad 21-71, 1314 BJ Almere en ingeschreven bij
      de Kamer van Koophandel onder nummer KvK Lelystad 32131672.
    </p>
    <h4>Waarom gebruikt Artikelgemist.nl persoonsgegevens?</h4>
    <p>
      Artikelgemist.nl wil de meest gewaardeerde en aanbevolen
      artikelnabesteldienst voor uitgevers in Nederland zijn en een excellent
      dienstverlener. Dat betekent dat wij rekening houden met wat klanten
      beweegt, want zij geven Artikelgemist.nl waarde. Om goed op wensen van
      klanten in te kunnen spelen maken wij gebruik van persoonsgegevens. Dat
      kan uiteraard alleen wanneer we uiterst zorgvuldig en binnen het kader van
      privacywet- en regelgeving omgaan met persoonsgegevens van klanten en
      overige relaties. Een persoonsgegeven is een gegeven dat direct of
      indirect iets over een persoon en/of relatie zegt.
    </p>

    <h4>Welke persoonsgegevens worden er door Artikelgemist.nl verwerkt?</h4>
    <p>
      Artikelgemist.nl kan naar uw gegevens vragen op de website. Het is geheel
      vrijwillig om uw persoonsgegevens op te geven, maar voor onze
      dienstverlening noodzakelijk om uw persoonsgegevens te gebruiken. Uw
      persoonsgegevens worden dan gebruikt om de juiste service aan u te
      leveren.
    </p>
    <h4>Welke gegevens worden er door Artikelgemist.nl online verzameld?</h4>
    <p>
      Op deze website kunt u gepubliceerde artikelen nabestellen. De gegevens
      welke nodig zijn om de door u bestelde artikelen af te rekenen en te
      verzenden worden vastgelegd. Het is niet noodzakelijk om een inlogaccount
      aan te maken. Alleen wanneer u ervoor kiest om een persoonlijk
      inlogaccount aan te maken, worden ook uw accountgegevens vastgelegd.
    </p>

    <h4>Cookies</h4>
    <p>
      Profactys gebruikt functionele en analytische cookies die geen inbreuk
      maken op uw privacy. Om de inhoud en het gebruik van onze website te
      volgen en te verbeteren, verzamelt Profactys het surfgedrag van onze
      websitebezoekers. U kunt in uw browser cookies altijd buiten werking
      stellen, zonder dat dit vergaande gevolgen heeft voor het gebruik van de
      website.
    </p>
    <h4>Hoe lang bewaart Artikelgemist.nl uw gegevens?</h4>
    <p>
      Artikelgemist.nl bewaart uw persoonsgegevens niet langer dan strikt nodig
      is om u van dienst te zijn. Dat wil zeggen dat wij uw gegevens bewaren
      zolang u klant bent. Na verloop van tijd worden verouderde
      persoonsgegevens verwijderd.
    </p>
    <h4>Aan wie verstrekt Artikelgemist.nl persoonsgegevens?</h4>
    <p>Artikelgemist.nl verstrekt geen persoonsgegevens aan derden.</p>
    <h4>Hoe beveiligt Artikelgemist.nl uw persoonsgegevens?</h4>
    <p>
      Artikelgemist.nl heeft passende beveiligingsmaatregelen getroffen om
      misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en
      ongeoorloofde wijziging of enige vorm van onrechtmatige verwerking zoveel
      mogelijk tegen te gaan. Artikelgemist.nl heeft zowel technische als
      organisatorische maatregelen genomen om de door u verstrekte
      persoonsgegevens te beveiligen. Deze beveiligingsmaatregelen worden
      periodiek herzien.
    </p>
    <h4>Uw gegevens inzien, wijzigen of verwijderen?</h4>
    <p>
      Wilt u de gegevens die Artikelgemist.nl van u heeft verzameld inzien of
      heeft u vragen binnen het kader van de privacywet- en regelgeving, dan
      kunt u uitsluitend schriftelijk contact met ons opnemen via
      avgprivacy@profactys.nl of Profactys, t.a.v. AVG, Postbus 1157, 1300 BD
      Almere.
    </p>
  </div>
);

export default privacyStatement;
