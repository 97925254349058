import React from "react";
import { NavLink } from "react-router-dom";

import "./Home.css";
import Button from "../UI/Button/Button";

/**
 * @author Elisa
 *
 * Simple home component.
 */
const home = props => (
  <div className="Home">
    <div className="HomeContainer">
      <h1>Op zoek naar een artikel uit de media?</h1>
      <p>
        Artikelgemist.nl biedt een eenvoudige manier om artikelen terug te
        vinden.
      </p>
      <Button
        id="registerButton"
        className="RegisterButton"
        clicked={() => props.history.push("/register")}
      >
        Gratis aanmelden
      </Button>
      <NavLink id="login" to="/login" className="LoginLink">
        Heb je al een account?
      </NavLink>
    </div>
  </div>
);

export default home;
