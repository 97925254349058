import React from "react";
import { FaBars } from 'react-icons/fa';

import "./Toolbar.css";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";

/**
 * @author Elisa Straatman
 *
 * Simple component for displaying the toolbar
 */
const toolbar = props => (
  <header className="Toolbar">
  <div className="DrawerToggleToolbar">
  <DrawerToggle clicked={props.drawerToggle}><FaBars/></DrawerToggle>
  </div>
      <Logo/>
    <div className="DesktopOnly">
      <NavigationItems />
    </div>
  </header>
);

export default toolbar;
