import React from "react";
import "./ErrorMessage.css";

/**
 * @author Elisa Straatman
 *
 * Error message component for global use
 */
let errorMessage = props => (
  <h6 className={"ErrorMessage"} id={props.id}>{props.children}</h6>
);

export default errorMessage;
