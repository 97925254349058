import React from "react";

import "./CartItem.css";
import { FaMinus } from "react-icons/fa";
import { php } from "locutus";
const { number_format } = php.strings;

/**
 * @author Elisa Straatman
 *
 * This is the layout for a single article as it is rendered in the cart
 */
const cartItem = props => (
  <div id={props.id} className="Cart">
    <div id={props.id} className="Product">
<img src={props.imageUrl} alt="" className="ProductImg" />

<div className="ProductInfo">
  <div className="ProductHeader">
    <h2>{props.headline}</h2>
    <p>€ {number_format(props.price, 2, ",", ".")}</p>
  </div>

  {/*Removes the <###> tags that Mediaweb includes in the text*/}
  <div className="ProductSummary">
    {props.summary.replace(/<###>|<\/###>/g, "")}
  </div>
  <div className="ProductPublication">
    <div className="ProductPublicationName"><strong>Bron: {props.pubName} </strong></div>
    <div className="ProductPublicationDate">
      <i>Publicatiedatum: {props.pubDate}{" "}</i>
    </div>

    <button
      id="removeButton"
      className="BasketButton"
      onClick={props.clicked}
    >
      Verwijderen
    </button>
  </div>
</div>

<button
  id="removeButtonMobile"
  className="BasketButtonMobile"
  onClick={props.clicked}
>
  <FaMinus />
</button>
</div>
</div>

);
export default cartItem;
