import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import axios from "axios";

import "./CartSummary.css";
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { toast } from "react-toastify";

import { php } from "locutus";
import { FaInfoCircle } from "react-icons/fa";
const { number_format } = php.strings;

/**
 * @author Elisa Straatman
 * Layout
 *
 * @author Jeffrey Hendrikse
 * @author Donny Smit
 * Functionality
 *
 * The information box that shows a summary of the cart
 */
class CartSummary extends Component {
  state = {
    // creditCardTransactionCost: 0.25,
    // ccAddedPercentage: 0.018,
    modal: false,
    totalPrice: 0,
    cartForm: {
      paymentMethod: {
        elementType: "select",
        elementConfig: {
          id: "paymentMethod",
          options: [
            { value: "ideal", displayValue: "iDeal (€0,29)" },
            // { value: "creditCard", displayValue: "Creditcard (€0,25 + 1.8%)" },
            {
              value: "balance",
              displayValue: "Betalen met saldo (geen transactiekosten)"
            }
          ]
        },
        value: "balance",
        validation: {},
        valid: true
      }
    },
    formIsValid: true
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.subtotalPrice !== this.state.totalPrice) {
      this.setState({ totalPrice: +nextProps.subtotalPrice });
    }
  }

  componentDidMount() {
    let priceMapper = this.props.cart.map(article => article.price);
    const prices = priceMapper.reduce((acc, priceMapper) => acc + priceMapper, 0);
    this.setState({ totalPrice: prices });
  }

  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    this.priceCalculator(event);
    const updatedCartForm = {
      ...this.state.cartForm
    };
    const updatedFormElement = {
      ...updatedCartForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedCartForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedCartForm) {
      formIsValid = updatedCartForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ cartForm: updatedCartForm, formIsValid: formIsValid });
  };

  /**
   * Checks which payment option is selected and changes the total price to match
   */
  priceCalculator = event => {
    if (event.target.value === "ideal") {
      let totalPrice =
        +this.props.subtotalPrice + this.props.transactionCostIdeal;
      this.setState({ totalPrice: totalPrice });
    } else if (event.target.value === "creditCard") {
      let ccAddedCost =
        +this.props.subtotalPrice * this.state.ccAddedPercentage;
      let totalPrice =
        +this.props.subtotalPrice +
        this.state.creditCardTransactionCost +
        ccAddedCost;
      this.setState({ totalPrice: totalPrice });
    } else {
      this.setState({ totalPrice: +this.props.subtotalPrice });
    }
  };

  /**
   * Retrieve the application token.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    return axios.post("auth/token", loginParams);
  };

  /**
   * Buys the articles.
   * @param i the index of the articles
   * @param token the required appToken
   */
  purchaseArticles = (i, token) => {
      axios.post("purchase/crud/" + this.props.cart[i].articleId + "?token=" + token);
  }

  /**
   * Handles the purchase/payment.
   */
  purchaseHandler = () => {
    this.getAppTokenHandler().then(res => {
      if (this.state.cartForm.paymentMethod.value === "balance") {
        for (let i = 0; i < this.props.cart.length; i++) {
          this.purchaseArticles(i, res.data.token);
        }
        this.props.setBalance(this.props.balance - this.state.totalPrice);
        this.clearCartHandler(res.data.token);
        this.props.setCart([]);
        toast.success("Gelukt! Uw artikel(en) zijn verzonden naar " + this.props.sendEmailAddress);
        this.setState({ modal: false });
      }
    })
  };

  /**
   * Clears the cart articles.
   * @param token the required appToken.
   */
  clearCartHandler = (token) => {
    let body = {
      sendEmailAddress: this.props.sendEmailAddress,
      displayName: this.props.displayName,
      settings: {
        cart: []
      }
    };
    axios.put("user/crud/" + this.props.userId + "?token=" + token, body, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  /**
   * Closes the modal.
   */
  modalClosedHandler = () => {
    this.setState({ modal: false });
  };

  /**
   * Opens the modal / Sends the user to Mollie.
   */
  modalOpenHandler = () => {
    if (this.state.cartForm.paymentMethod.value === "ideal") {
      this.getAppTokenHandler().then(res => {
        let body = {
          amount: +this.props.subtotalPrice,
          description: "Artikelgemist.nl - Artikelen aankopen"
        };
        axios
          .post("payment/crud?token=" + res.data.token, body, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(response => {
            window.open(response.data.mollieUrl, "_blank");
            window.close();
          });
      })
    }
    else {
      this.setState({ modal: true });
    }
  };

  render() {
    //Transforms form to array necessary for mapping later in return function
    const formElementsArray = [];
    for (let key in this.state.cartForm) {
      formElementsArray.push({
        id: key,
        config: this.state.cartForm[key]
      });
    }

    let futureBalance = this.props.balance - this.state.totalPrice;

    const modal = 
      this.props.balance - this.state.totalPrice >= 0 ? (
        <Modal show={this.state.modal} modalClosed={this.modalClosedHandler} >
          <div className="Modal">
            <br />
            <h3>Artikelen aankopen</h3>
            <hr />
            <p>
              Weet u zeker dat u <b>{this.props.cart.length}</b> artikel(en)
              wilt kopen? <br /> Er wordt{" "}
              <b>€{number_format(this.state.totalPrice, 2, ",", ".")}</b>{" "}
              van uw saldo afgeschreven
            </p>
            <p>
              Huidig saldo:{" "}
              <b>€{number_format(this.props.balance, 2, ",", ".")}</b>
            </p>
            <p>
              Saldo na betaling:{" "}
              <b>€{number_format(futureBalance, 2, ",", ".")}</b>
            </p>
            <Button id="purchaseYes" clicked={this.purchaseHandler}>
              JA
            </Button>
            <Button id="purchaseNo" clicked={this.modalClosedHandler}>
              NEE
            </Button>
          </div>
        </Modal>
      ) : <Modal show={this.state.modal} modalClosed={this.modalClosedHandler}>
          <div className="Modal">
            <p>U heeft niet genoeg saldo om deze actie te voltooien.</p>
            <p>
              <NavLink id="balance" to="/settings/balance">
                <b>klik hier</b>
            </NavLink> om uw saldo op te waarderen
          </p>
          </div>
        </Modal>

    const cartSummary = (
      <aside>
        <div className="CartSummary">
          <div id="totalItems" className="CartSummaryTotalItems">
            Betalingsoverzicht
          </div>
          <div id="subTotal" className="CartSummarySubtotal">
            <span>Subtotaal</span>€{" "}
            {number_format(this.props.subtotalPrice, 2, ",", ".")}
          </div>
          <form onSubmit={this.purchaseHandler}>
            {formElementsArray.map(formElement => (
              <Input
                id={formElement.config.elementConfig.id}
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
                message={formElement.config.message}
              />
            ))}
          </form>
          <p>
            Door op 'bestellen' te klikken gaat u akkoord met de{" "}
            <NavLink id="terms" to="/terms">
              <b>algemene voorwaarden<sup><FaInfoCircle/></sup></b>
            </NavLink>
          </p>
          <div className="CartSummaryTotal">
            <div className="Total">Totaal</div>
            <strong id="total">
              € {number_format(this.state.totalPrice, 2, ",", ".")}
            </strong>
          </div>
          <button
            id="orderButton"
            onClick={this.modalOpenHandler}
            disabled={!this.state.formIsValid}
          >
            Bestellen
          </button>
        </div>
      </aside>
    );

    return (
      <>
        {modal}
        {this.props.cartItems > 0 ? cartSummary : null}
      </>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    cart: state.cart,
    displayName: state.displayName,
    sendEmailAddress: state.sendEmailAddress,
    balance: state.balance,
    logintoken: state.logintoken,
    userId: state.userId,
    transactionCostIdeal: state.transactionCostIdeal
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    setBalance: balance => dispatch({ type: "SET_BALANCE", value: balance }),
    setCart: cart => dispatch({ type: "SET_CART", value: cart })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSummary);