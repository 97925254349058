//Redux: https://redux.js.org/basics/actions
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_ALL_FROM_CART = 'REMOVE_ALL_FROM_CART';
export const SEARCH_BOOLEAN = 'SEARCH_BOOLEAN';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const IS_WHITELABEL = 'IS_WHITELABEL';
export const SEARCH_STRING = 'SEARCH_STRING';
export const SET_LOGINTOKEN = 'SET_LOGINTOKEN';
export const SORT_TYPE = 'SORT_TYPE';
export const PERIOD_TYPE = 'PERIOD_TYPE';
export const START_DATE = 'START_DATE';
export const END_DATE = 'END_DATE';
export const SOURCE = 'SOURCE';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_CUSTOMERLINKID = 'SET_CUSTOMERLINKID';
export const SET_DISPLAYNAME = 'SET_DISPLAYNAME';
export const SET_SENDEMAILADDRESS = 'SET_SENDEMAILADDRESS';
export const SET_USERID = 'SET_USERID';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_CART = 'SET_CART';
export const SET_WHITELABELHOST = 'SET_WHITELABELHOST';