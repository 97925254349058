import React from "react";

import "./Legal.css";

/**
 * @author Elisa Straatman
 * Legal documents
 */
const disclaimer = () => (
  <div className="Legal" id="disclaimer">
    <h1>Algemene voorwaarden</h1>
    <p>
      De besloten vennootschap Profactys, gevestigd te Almere, hierna te noemen: ‘Profactys’.
      </p>
      <ol>
        <p>
        <li>
          Het onderstaande is van toepassing op de volledige inhoud van de
          website Artikelgemist.nl. Door gebruik te maken van de website
          Artikelgemist.nl stemt u in met deze Algemene Voorwaarden.
        </li>
        <li>
          Profactys spant zich in een zo groot mogelijk en actueel aanbod van de
          inhoud van de website Artikelgemist.nl regelmatig te actualiseren
          en/of aan te vullen. Ondanks deze zorg en aandacht is het mogelijk dat
          de inhoud onvolledig en/of onjuist is.
        </li>
        <li>
          Profactys verschaft toegang tot de inhoud van de website
          Artikelgemist.nl zonder garantie of waarborg ten aanzien van de
          deugdelijkheid, geschiktheid voor een bepaald doel of anderszins. De
          inhoud is voor particulier eigen gebruik bedoeld.
        </li>
        <li>
          Profactys dan wel de deelnemende uitgevers c.q. auteurs zijn op
          generlei wijze aansprakelijk voor schade die is of dreigt te worden
          toegebracht en voortvloeit uit of in enig opzicht verband houdt met de
          inhoud en of het gebruik van de website Artikelgemist.nl of met de
          onmogelijkheid de webpagina te kunnen raadplegen.
        </li>
        <li>
          Het is Profactys toegestaan de website Artikelgemist.nl naar eigen
          inzicht en op ieder gewenst moment te (laten) veranderen of te
          beëindigen, met of zonder voorafgaande verwittiging. Profactys is niet
          aansprakelijk voor de gevolgen van verandering of beëindiging.
        </li>
        <li>
          Behoudens deze Algemene Voorwaarden, zijn de deelnemende uitgevers
          c.q. auteurs niet verantwoordelijk voor kenbaar aan de website
          Artikelgemist.nl gekoppelde bestanden van derden. Koppeling houdt geen
          bekrachtiging van die bestanden in.
        </li>
        <li>
          Ongeautoriseerd of oneigenlijk gebruik van de website Artikelgemist.nl
          of de inhoud daarvan kan een inbreuk op auteursrecht, databankrecht
          dan wel intellectuele eigendomsrechten betekenen. U bent
          verantwoordelijk voor al hetgeen u met de aangekochte artikelen doet
          anders dan voor eigen gebruik. Kosten voortvloeiend uit
          ongeautoriseerd of oneigenlijk gebruik worden te allen tijde verhaald
        </li>
        <li>
          Profactys behoudt zich het recht voor om de toegang tot de website te
          ontzeggen wanneer sprake is van oneigenlijk gebruik van de website
          Artikelgemist.nl. In vervolg op de constatering is het Profactys
          toegestaan het naar eigen inzicht een redelijke financiële
          genoegdoening te eisen voor oneigenlijk gebruik van artikelen,
          schending van het auteursrecht, databankrecht, intellectueel
          eigendomsrecht. In aansluiting daarop kan Profactys de toegang tot de
          website monitoren.
        </li>
        <li>
          U zult Profactys, diens werknemers, vertegenwoordigers,
          licentiehouders, handelspartners en de auteur van deze Algemene
          Voorwaarden beschermen tegen en vrijwaren van gerechtelijke en
          buitengerechtelijke maatregelen, veroordelingen e.d., inclusief de
          kosten voor rechtsbijstand, accountants e.d. die door derden zijn
          ingesteld ten gevolge van of gerelateerd aan uw gebruik van
          Artikelgemist.nl, uw inbreuk op welke wettelijke regeling dan ook of
          de rechten van derden.
        </li>
        </p>
      </ol>
  </div>
);

export default disclaimer;
