import React, { Component } from "react";
import axios from 'axios';
import { connect } from "react-redux";
import {toast} from "react-toastify";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import "./ChangePassword.css";

/**
 * @author Donny Smit
 * Rest
 * 
 * @author Jeffrey Hendrikse
 * API call, error handling, ComponentWillUnmount
 *
 * Component for changing a user's password while logged in
 */
class ChangePassword extends Component {
  state = {
    error: false,
    apiError: false,
    success: false,
    samePasswordError: false,
    passwordForm: {
      oldPassword: {
        elementType: "input_2",
        elementConfig: {
          id: "oldPassword",
          type: "password",
          placeholder: "Vul uw huidige wachtwoord in"
        },
        value: "",
        label: "Huidig wachtwoord",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      newPassword: {
        elementType: "input_2",
        elementConfig: {
          id: "newPassword",
          type: "password",
          placeholder: "Vul uw nieuwe wachtwoord in"
        },
        value: "",
        label: "Nieuw wachtwoord instellen",
        validation: {
          required: true,
          strengthTest: true
        },
        valid: false,
        touched: false
      },
      confirmPassword: {
        elementType: "input_2",
        elementConfig: {
          id: "confirmPassword",
          type: "password",
          placeholder: "Bevestig uw nieuwe wachtwoord"
        },
        value: "",
        label: "Bevestig nieuw wachtwoord",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  };

  //Reset state for errormessages when component unmounts
  componentWillUnmount() {
    this.setState({ succes: false, apiError: false, error: false, samePasswordError: false });
  }
  /**
   * Checks validity of input fields, see validation in state. Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    //Password has to contain at least {1 lowercase, 1 UPPERCASE, 1 number, 1 special character, min of 8 characters long}
    if (rules.strengthTest) {
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      isValid = regex.test(value);
    }

    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedPasswordForm = {
      ...this.state.passwordForm
    };
    const updatedFormElement = {
      ...updatedPasswordForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedPasswordForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedPasswordForm) {
      formIsValid = updatedPasswordForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      passwordForm: updatedPasswordForm,
      formIsValid: formIsValid
    });
  };

  /**
   * Checks and changes the password.
   */
  changePasswordHandler = event => {
    event.preventDefault();
    this.setState({ succes: false, apiError: false, error: false, samePasswordError: false });
    if (this.state.formIsValid) {
      if (
        this.state.passwordForm.oldPassword.value ===
        this.state.passwordForm.newPassword.value
      ) {
        toast.error(
          <p id="samePasswordError">Uw nieuwe wachtwoord mag niet overeenkomen met uw huidige wachtwoord.</p>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      } else if (
        this.state.passwordForm.newPassword.value ===
        this.state.passwordForm.confirmPassword.value
      ) {
        let loginParams = new URLSearchParams();
        loginParams.append("token", this.props.logintoken);
        axios
          .post("auth/token", loginParams)
          .then(response => {
            let params = new URLSearchParams();
            params.append(
              "current_password",
              this.state.passwordForm.oldPassword.value
            );
            params.append(
              "new_password",
              this.state.passwordForm.newPassword.value
            );
            axios
              .post(
                "auth/new_password?token=" +
                  response.data.token,
                params
              )
              .then(response => {
                toast.success(
                  <p id="samePasswordError">Uw wachtwoord is succesvol gewijzigd.</p>,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                  }
                );
              })
              .catch(error => {
                toast.error(
                  <p id="wrongPasswordError">Uw huidige wachtwoord is niet correct.</p>,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                  }
                );
              });
          });
      } else {
        toast.error(
          <p id="samePasswordError">De nieuwe wachtwoorden komen niet overeen.</p>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      }
    }
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.passwordForm) {
      formElementsArray.push({
        id: key,
        config: this.state.passwordForm[key]
      });
    }

    return (
      <div className="ChangePassword">
        <h1>Wachtwoord wijzigen</h1>
        <p>
          Het wachtwoord van uw account kunt u wijzigen door hieronder uw
          huidige wachtwoord in te vullen en daarna een nieuw wachtwoord te
          kiezen.
        </p>
        <form onSubmit={this.changePasswordHandler}>
          {formElementsArray.map(formElement => (
            <div key={formElement.id}>
              <Input
                id={formElement.config.elementConfig.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
              <br />
            </div>
          ))}
          <Button id="passwordButton" disabled={!this.state.formIsValid}>
            Opslaan
          </Button>
        </form>
      </div>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {logintoken: state.logintoken};
};

export default connect(mapStateToProps)(ChangePassword);

