import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

import "./Cart.css";
import CartItem from "../../components/CartItem/CartItem";
import CartSummary from "../../components/CartSummary/CartSummary";
import Button from "../../components/UI/Button/Button";
import Moment from "moment";
import axios from "axios";

/**
 * @author Donny (Core)
 * @author Elisa (CSS)
 * @author Jeffrey (CartSummary)
 *
 * Container for Cart which contains everything regarding the cart.
 **/
class Cart extends Component {
  _isMounted = false;

  state = {
    selectedArticle: [],
    price: 0,
    purchasing: false,
    articles: []
  };

  componentWillMount() {
    if ((this.props.auth && this.props.whitelabel) || !this.props.auth) {
      this.props.history.push("/");
    }
  }

  //Get articles from db.
  componentDidMount() {
    this._isMounted = true;
    this.getArticlesFromDB();
  }

  //Memory leak fix.
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Remove selected article from DB.
   */
  deleteInDB = (token, cartArr, index) => {
    let body = {
      sendEmailAddress: this.props.sendEmailAddress,
      displayName: this.props.displayName,
      settings: {
        cart: [...cartArr.slice(0, index), ...cartArr.slice(index + 1)]
      }
    };
    axios.put("user/crud/" + this.props.userId + "?token=" + token, body, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  };

  /**
   * Handles what happens when clicked on the delete button
   */
  deleteClickHandler = (id, price) => {
    let index = this.props.cart.findIndex(article => {
      return article.articleId === id;
    });
    toast.error("Artikel verwijderd uit winkelwagentje!");
    const oldPrice = this.state.price;
    let newPrice = oldPrice - price;
    this.setState({ price: newPrice.toFixed(2) });
    this.getAppTokenHandler().then(tokenResponse => {
      axios.get("user/crud?token=" + tokenResponse.data.token).then(res => {
        this.deleteInDB(
          tokenResponse.data.token,
          res.data.settings.cart,
          index
        );
      });
    });
    this.props.RemoveArticleCart(index);
  };

  /**
   * Toggles between cart and cartSummary
   */
  mobileCartSummaryToggle = () => {
    this.setState(prevState => {
      return { showCartSummary: !prevState.showCartSummary };
    });
  };

  /**
   * Retrieve the application token.
   */
  getAppTokenHandler = () => {
    let loginParams = new URLSearchParams();
    loginParams.append("token", this.props.logintoken);
    return axios.post("auth/token", loginParams);
  };

  getArticlesFromDB = () => {
    this.getAppTokenHandler().then(res => {
      axios.get("user/crud?token=" + res.data.token).then(res => {
        if (this._isMounted) {
          this.setState({
            articles: res.data.settings.cart
          });
          let sum = this.state.articles
            .reduce((acc, article) => {
              return acc + article.price;
            }, this.state.price)
            .toFixed(2);
          this.setState({ price: sum });
          this.props.syncReduxCart(res.data.settings.cart);
        }
      });
    });
  };

  goBackHandler = () => {
    this.props.history.push("/articles");
  };

  render() {
    const orderSummaryMobile = (
      <div className="PurchaseMobile">
        <CartSummary
          subtotalPrice={this.state.price}
          cartItems={this.props.cart.length}
        />
      </div>
    );

    let cartItems = (
      <h5 id="noCartItems">
        Uw winkelwagentje is leeg, voeg artikelen toe aan uw winkelwagentje!
      </h5>
    );

    const header = (
      <div className="CartItemHeader">
        <ul>
          <li>Artikel</li>
          <li>Prijs</li>
        </ul>
      </div>
    );

      if (this.props.cart.length > 0) {
        cartItems = this.props.cart.map(article => {
          return (
            <CartItem
              id={article.articleId}
              clicked={() =>
                this.deleteClickHandler(article.articleId, article.price)
              }
              key={article.articleId}
              headline={article.headline}
              summary={article.summary}
              pubType={article.publicationType}
              pubName={article.publicationName}
              pubDate={Moment(article.publicationDate).format("DD-MM-YYYY")}
              price={article.price.toFixed(2)}
              imageUrl={article.imageUrl}
            />
          );
        });
    }

    return (
      <>
        <div className="Cart">
          <header className="CartHeader">
            <Button clicked={this.goBackHandler}>
              <FaArrowLeft />
              <div className="ReturnButtonText" id="backToSearch">
                Terug naar zoekresultaten
              </div>
            </Button>
            {this.props.cart.length === 1 ? (
              <h1>Winkelmand ({this.props.cart.length} artikel)</h1>
            ) : (
              <h1>Winkelmand ({this.props.cart.length} artikelen)</h1>
            )}
          </header>
          <div className="OrderMobile">
            {this.state.showCartSummary ? (
              <Button
                disabled={this.props.cart.length <= 0}
                clicked={this.mobileCartSummaryToggle}
              >
                Terug naar winkelwagentje
              </Button>
            ) : (
              <Button
                disabled={this.props.cart.length <= 0}
                clicked={this.mobileCartSummaryToggle}
              >
                Naar betalen
              </Button>
            )}
          </div>
          {!this.state.showCartSummary ? header : null}
          {this.state.showCartSummary ? orderSummaryMobile : cartItems}
          <div className="SummaryPosition">
            <CartSummary
              subtotalPrice={this.state.price}
              cartItems={this.props.cart.length}
            />
          </div>
        </div>
      </>
    );
  }
}

//Redux: https://redux.js.org
const mapStateToProps = state => {
  return {
    cart: state.cart,
    logintoken: state.logintoken,
    displayName: state.displayName,
    userId: state.userId,
    whitelabel: state.whitelabel,
    auth: state.login
  };
};

//Redux: https://redux.js.org
const mapDispatchToProps = dispatch => {
  return {
    RemoveArticleCart: index =>
      dispatch({ type: "REMOVE_FROM_CART", payload: index }),
    syncReduxCart: cart => dispatch({ type: "SET_CART", value: cart })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
