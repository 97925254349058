import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FaUserPlus,
  FaUserLock,
  FaShoppingCart,
  FaUserCog,
  FaSignOutAlt,
  FaArrowRight
} from "react-icons/fa";

import "./NavigationItems.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import { php } from "locutus";

const { number_format } = php.strings;

/**
 * @author Elisa Straatman
 * Layout and functionality
 *
 * @author Donny Smit
 * Redux
 *
 * Component that shows navigation items in top toolbar
 */
class NavigationItems extends Component {
  render() {
    return (
      <ul className="NavigationItems">
        {this.props.whitelabel ? 
            <a href="https://artikelgemist.nl">Ga naar de algemene website <FaArrowRight/></a>
          : null}
        {!this.props.auth || this.props.whitelabel ? null : (
          <NavigationItem id="balance" link="/settings/balance">
            <p>Huidig saldo: € {number_format(this.props.balance, 2, ",", ".")}</p>
          </NavigationItem>
        )}
        {!this.props.auth || this.props.whitelabel ? null : (
          <NavigationItem id="cart" link="/cart">
            <p>Winkelwagentje </p>
            <FaShoppingCart className="NavigationIcon" />
            {!this.props.whitelabel ? (
              <sup>({this.props.cart.length})</sup>
            ) : null}
          </NavigationItem>
        )}
        {!this.props.auth && !this.props.whitelabel ? (
          <NavigationItem id="headerLogin" link="/login">
            <p>Inloggen </p>
            <FaUserLock className="NavigationIcon" />
          </NavigationItem>
        ) : null}
        {this.props.auth && !this.props.whitelabel ? (
          <div className="Mobile">
            <NavigationItem id="settings" link="/settings">
              <p>Instellingen </p>
              <FaUserCog className="NavigationIcon" />
            </NavigationItem>
          </div>
        ) : null}
        {!this.props.auth ? (
          <NavigationItem id="headerRegister" link="/register">
            <p>Registreren </p>
            <FaUserPlus className="NavigationIcon" />{" "}
          </NavigationItem>
        ) : null}
        {this.props.auth && !this.props.whitelabel ? (
          <NavigationItem id="headerLogout" link="/logout">
            <p>Uitloggen </p>
            <FaSignOutAlt className="NavigationIcon" />
          </NavigationItem>
        ) : null}
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.login,
    cart: state.cart,
    balance: state.balance,
    whitelabel: state.whitelabel
  };
};

export default connect(mapStateToProps)(NavigationItems);