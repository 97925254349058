import React from "react";
import { NavLink } from "react-router-dom";

import "./NoMatch.css";

/**
 * @author Jeffrey Hendrikse
 *
 * Stateless component that will be displayed when the user reaches a route that doesn't exist.
 */
const noMatch = () => (
  <div className="NoMatch">
    <h2>Pagina niet gevonden</h2>
    <p>De pagina die u probeert te bereiken bestaat niet.</p>
    <NavLink id="404Home" to="/">
      Klik hier om naar de Homepagina te gaan
    </NavLink>
  </div>
);

export default noMatch;
