import React, { Component, Suspense } from "react";
import Button from "../../UI/Button/Button";
import Spinner from "../../UI/Spinner/Spinner";

import "./OrderHistory.css";

//Lazy loading of the different tabs, to reduce loading times
const LazyArticles = React.lazy(() =>
  import("./ArticleHistory/ArticleHistory")
);
const LazyBalance = React.lazy(() => import("./BalanceHistory/BalanceHistory"));

class OrderHistory extends Component {
  state = {
    showArticles: true,
    showBalance: false
  };

  /**
   * Handler that allows for toggling of the different tabs
   * @param event
   */
  toggleHandler = event => {
    if (event.target.id === "showArticles") {
      this.setState({
        showArticles: true,
        showBalance: false
      });
    }
    if (event.target.id === "showBalance") {
      this.setState({
        showArticles: false,
        showBalance: true
      });
    }
  };

  render() {
    return (
      <div className="OrderHistory">
        <div className="OrderToggle">
          <Button clicked={this.toggleHandler} id="showArticles">
            Artikelen
          </Button>
          <Button clicked={this.toggleHandler} id="showBalance">
            Saldo
          </Button>
        </div>

        {this.state.showArticles ? (
          <Suspense
            fallback={
              <div className="Spinner">
                <Spinner />
              </div>
            }
          >
            <LazyArticles />
          </Suspense>
        ) : null}
        {this.state.showBalance ? (
          <Suspense
            fallback={
              <div className="Spinner">
                <Spinner />
              </div>
            }
          >
            <LazyBalance />
          </Suspense>
        ) : null}
      </div>
    );
  }
}

export default OrderHistory;
