import React from "react";
import "./Button.css";

/**
 * @author Elisa Straatman
 *
 * Basic button component that is used for buttons in this application.
 */
const button = props => (
  <button
    id={props.id}
    className="Button"
    disabled={props.disabled}
    onClick={props.clicked}
  >
    {props.children}
  </button>
);

export default button;
