import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import "./Register.css";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";

/**
 * @author Jeffrey Hendrikse
 *
 * @author Elisa Straatman
 * toast
 *
 * The register component, handles the registration of users.
 */
class Register extends Component {
  state = {
    registerForm: {
      email: {
        elementType: "input_2",
        elementConfig: {
          id: "email",
          type: "email",
          placeholder: "Vul uw e-mailadres in"
        },
        value: "",
        label: "E-mailadres",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      confirmEmail: {
        elementType: "input_2",
        elementConfig: {
          id: "confirmEmail",
          type: "email",
          placeholder: "Bevestig uw e-mailadres"
        },
        value: "",
        label: "E-mailadres bevestigen",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      displayName: {
        elementType: "input_2",
        elementConfig: {
          id: "name",
          type: "text",
          placeholder: "Vul uw gewenste schermnaam in"
        },
        value: "",
        label: "Schermnaam",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      checkbox: {
        elementType: "checkbox",
        elementConfig: {
          id: "termsCheck",
          type: "checkbox"
        },
        message: "Ik ga akkoord met de algemene voorwaarden",
        validation: {},
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    error: false
  };

  /*
   * Criterium that has to be met when reaching this component.
   * If user is already logged in, push them to home
   */
  componentWillMount() {
    if (this.props.auth) {
      this.props.history.push("/");
    }
  }

  /**
   * Checks validity of input fields, see validation in state. Returns boolean.
   */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedRegisterForm = { ...this.state.registerForm };
    const updatedFormElement = { ...updatedRegisterForm[inputIdentifier] };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedRegisterForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedRegisterForm) {
      formIsValid = updatedRegisterForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      registerForm: updatedRegisterForm,
      formIsValid: formIsValid
    });
  };

  /**
   * When form is submitted, checks if email fields are the same value, if false, throw error. If true, call formSubmitHandler
   * which sends the screenname and username to the API and then calls the API to send a password-request email to the new user
   */
  formSubmitHandler = event => {
    event.preventDefault();
    if (
      this.state.formIsValid &&
      this.state.registerForm.email.value ===
        this.state.registerForm.confirmEmail.value
    ) {
      let registerParams = {
        displayName: this.state.registerForm.displayName.value,
        userName: this.state.registerForm.email.value
      };
      axios
        .post("auth/crud", registerParams, {
          headers: { "Content-Type": "application/json" }
        })
        .then(() => {
          let params = new URLSearchParams();
          params.append("username", this.state.registerForm.email.value);
          params.append("type", "ArtikelGemist");
          this.props.setSendEmailAddress(this.state.registerForm.email.value);
          axios
            .post(
              "auth/reset_password_request",
              params
            )
            .then(() => {
              toast.success(
                <p id="emailSentSucces">
                  Uw registratie is voltooid! Er is een e-mail verstuurd naar:
                  <br />
                  {this.state.registerForm.email.value}
                </p>,
                {
                  position: "top-right",
                  autoClose: 50000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                }
              );
            });
        })
        .catch(error => {
          toast.error(
            <p id="apiError">Ongeldige gebruiker of gebruiker bestaat al.</p>,
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        });
    } else {
      toast.error(
        <p id="emailSentError">
          De ingevulde e-mailadressen komen niet overeen.
        </p>,
        {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
    }
  };

  render() {
    //Transforms loginForm to array for mapping later in return function
    const formElementsArray = [];
    for (let key in this.state.registerForm) {
      formElementsArray.push({
        id: key,
        config: this.state.registerForm[key]
      });
    }

    return (
      <div className="Register">
        <div className="RegisterBox">
          <h1>Registreren</h1>
          <h5>
            Na het invoeren van uw e-mailadres krijgt u van ons een e-mail
            toegestuurd voor het maken van een wachtwoord.
          </h5>

          <form onSubmit={this.formSubmitHandler}>
            {formElementsArray.map(formElement => (
              <Input
                id={formElement.config.elementConfig.id}
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
                message={formElement.config.message}
              />
            ))}
            <div className="Container">
              <Button id="buttonRegister" disabled={!this.state.formIsValid}>
                Registreren
              </Button>
              <NavLink id="login" to="/login">
                Heeft u al een account?
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

//Redux: https://redux.js.org/
const mapStateToProps = state => {
  return {
    auth: state.login
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    setSendEmailAddress: sendEmailAddress =>
      dispatch({ type: "SET_SENDEMAILADDRESS", value: sendEmailAddress })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
