import React from "react";
import {FaFileDownload} from "react-icons/fa";
import PublicationList from "../../assets/PublicatieoverzichtArtikelgemist.pdf"

import "./Legal.css";

/**
 * @author Jeffrey Hendrikse
 *
 * Legal documents
 */
const Media = () => (
  <div className="Legal" id="media">
    <h1>Beschikbare publicaties</h1>
    <p>
      Profactys BV heeft voor Artikelgemist.nl afspraken gemaakt met meer dan
      <b> 250</b> uitgevers die samen bijna <b>900</b> unieke uitgaves beschikbaar stellen.
      Om de volledige titellijst te downloaden, klikt u op de onderstaande knop.
    </p>
    <br/>
    <a href={PublicationList} id="downloadPublicationList" download><FaFileDownload/> Download volledige titellijst</a>
  </div>
);

export default Media;
