import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Order from './components/Order/Order';
import Settings from "./containers/Settings/Settings";
import Logout from "./components/Logout/Logout";
import Forgot from "./components/Forgot/Forgot";
import Cart from "./containers/Cart/Cart";
import ArticleViewer from "./containers/ArticleViewer/ArticleViewer";
import Layout from "./hoc/Layout/Layout";
import Contact from "./components/Legal/Contact";
import AboutUs from "./components/Legal/AboutUs";
import Disclaimer from "./components/Legal/Disclaimer";
import PrivacyStatement from "./components/Legal/PrivacyStatement";
import Media from "./components/Legal/Media";
import NoMatch from "./components/NoMatch/NoMatch";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ArticleSearch from "./containers/ArticleSearch/ArticleSearch";
import SetPassword from "./components/SetPassword/SetPassword";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            transition={Slide}
          />
        </div>
        <Layout {...this.props}>
          <Switch>
            <Route path="/payment/order/:id" component={Order} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/articles" component={ArticleViewer} />
            <Route path="/cart" component={Cart} />
            <Route path="/contact" component={Contact}/>
            <Route path="/about" component={AboutUs} />
            <Route path="/terms" component={Disclaimer} />
            <Route path="/privacy" component={PrivacyStatement} />
            <Route path="/media" component={Media} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/setpassword" component={SetPassword} />
            <Route path="/settings" component={Settings} />
            <Route path="/" exact component={ArticleSearch} />
            <Route path="*" component={NoMatch} />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default App;
