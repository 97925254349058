import React from "react";

import "./Backdrop.css";

/**
 * @author Jeffrey Hendrikse
 *
 * Basic backdrop component. Used on the background when the modal is opened.
 */
const backdrop = props =>
  props.show ? <div className="Backdrop" onClick={props.clicked} /> : null;

export default backdrop;
