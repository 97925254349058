import React from "react";
import "./Input.css";

/**
 * @author Jeffrey Hendrikse
 *
 * Input component that is used for all the inputs in this application.
 */
const input = props => {
  let inputElement = null;

  //Used for error validation.
  const inputClasses = ["InputElement"];
  const input2Classes = ["Input2"];
  const checkbox = ["Checkbox"];
  const select = ["Select"];

  switch (props.elementType) {
    case "input_2":
      inputElement = (
        <input
          className={input2Classes.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "searchbar":
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          className={inputClasses}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={select}
          id={props.elementConfig.id}
          value={props.value}
          onChange={props.changed}
          required
        >
          {props.elementConfig.options.map(option => (
            <option key={option.displayValue} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "checkbox":
      inputElement = (
        <>
          <input
            className={checkbox}
            {...props.elementConfig}
            onChange={props.changed}
            required
          />
          <p>{props.message}</p>
        </>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  const label = (
    <>
      <label className="Label">{props.label}</label>
      <div className="Flex">{inputElement}</div>
    </>
  );

  const noLabel = (
    <>
      <div className="Flex">{inputElement}</div>
    </>
  );

  return (
    <div>
      {props.elementType === "checkbox" ||
      props.elementType === "searchbar" ||
      props.elementType === "select"
        ? noLabel
        : label}
    </div>
  );
};

export default input;
