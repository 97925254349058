import React from "react";

import "./Legal.css";

/**
 * @author Elisa Straatman
 * Legal documents
 */
const contact = () => (
  <div className="Legal" id="contact">
    <h1>Contact</h1>
    <p>
      Mocht u problemen ondervinden bij het gebruik van deze website, laat ons
      dit dan weten door een e-mail te sturen naar: <b>info@artikelgemist.nl.</b><br/>
      Wij zullen ons uiterste best doen het probleem zo spoedig mogelijk te
      verhelpen.
    </p>
    <h4>Bedrijfsgegevens</h4>
    <p className="Contact">
      <li>Profactys BV</li>
      <li>Randstand 21-71, 1314 BJ Almere</li>
      <li>Postbus 1157, 1300 BD Almere</li>
      <li>Telefoonnummer: 036 549 5555</li>
      <li>www.profactys.nl</li>
      <li>KvK Lelystad 32131672</li>
      <li>BTW-nummer NL819027133B01</li>
    </p>
  </div>
);

export default contact;
