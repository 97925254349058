import React, { Component } from "react";

import "./Timer.css";

/**
 * Retrieved From: https://stackoverflow.com/questions/40885923/countdown-timer-in-react.
 * This timer has been editted to work with the application without errors.
 * Basic timer component that counts down for the logout component.
 */
class Timer extends Component {
  constructor() {
    super();
    this.state = { time: {}, seconds: 5 };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      s: seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  //Clear interval when component unmounts to avoid memory leak.
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });

    // Check if we're at zero.
    if (this.state.seconds === 0) {
      clearInterval(this.timer);
      this.props.history.push("/");
    }
  }

  render() {
    return <div className="Timer">{this.state.time.s}</div>;
  }
}

export default Timer;
