import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import "./Login.css";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";

/**
 *  @author Jeffrey Hendrikse
 *  LoginHandler, Form/input related functionality
 *
 *  @author Elisa Straatman
 *  Layout
 *
 *  @author Donny Smit
 *  errorMessage, setAppTokenHandler, getUserHandler
 *
 *  Component that handles login by a user
 */
class Login extends Component {
  state = {
    loginForm: {
      email: {
        elementType: "input_2",
        elementConfig: {
          id: "email",
          type: "email",
          placeholder: "Vul uw e-mailadres in"
        },
        value: "",
        label: "E-mailadres",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: "input_2",
        elementConfig: {
          id: "password",
          type: "password",
          placeholder: "Vul uw wachtwoord in"
        },
        value: "",
        label: "Wachtwoord",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    error: false
  };

  componentWillMount() {
    if (this.props.auth) {
      this.props.history.goBack();
    }
  }

  /**
   * Checks validity of input fields, see validation in state.
   */
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  /**
   * Handles input for input fields, sends them to checkValidity. Validates entire form when all fields are valid
   */
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedLoginForm = {
      ...this.state.loginForm
    };
    const updatedFormElement = {
      ...updatedLoginForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedLoginForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedLoginForm) {
      formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ loginForm: updatedLoginForm, formIsValid: formIsValid });
  };

  /**
   * [1/3] Handles the authenticating of the user.
   */
  loginHandler = event => {
    event.preventDefault();
    let params = new URLSearchParams();
    params.append("username", this.state.loginForm.email.value);
    params.append("password", this.state.loginForm.password.value);
    axios
      .post("auth/login", params)
      .then(response => {
        this.props.setToken(response.data.token);
        this.setAppTokenHandler(response.data.token);
        toast.dismiss();
      })
      .catch(error => {
        this.setState({ error: true });
        toast.error(
          <p id="loginError">De ingevulde gegevens zijn onjuist.</p>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      });
  };

  /**
   * [2/3] Sets the application token that is neccesary for retrieving user data.
   */

  setAppTokenHandler = token => {
    let loginToken = new URLSearchParams();
    loginToken.append("token", token);
    axios.post("auth/token", loginToken).then(response => {
      this.getUserHandler(response.data.token);
    });
  };

  /**
   * [3/3] Get the user information using the application token.
   */
  getUserHandler = appToken => {
    axios.get("user/crud?token=" + appToken).then(response => {
      //SendEmailAddress should be userName if it hasn't been set yet.
      this.props.setSendEmailAddress(response.data.sendEmailAddress);
      if (response.data.sendEmailAddress.length < 1) {
        this.props.setSendEmailAddress(response.data.userName);
        let body = {
          sendEmailAddress: response.data.userName,
          displayName: response.data.displayName,
          settings: response.data.settings
        };
        axios.put(
          "user/crud/" + response.data.userId + "?token=" + appToken,
          body
        );
      }

      if (response.data.settings !== null) {
        if (response.data.settings.cart.length > 0) {
          this.props.setCart(response.data.settings.cart);
        } else {
          this.props.setCart([]);
        }
      }

      //If cart doesn't exist yet, add it to the user settings.
      if (response.data.settings === null) {
        let body = {
          sendEmailAddress: response.data.userName,
          displayName: response.data.displayName,
          settings: {
            cart: []
          }
        };
        axios.put(
          "user/crud/" + response.data.userId + "?token=" + appToken,
          body
        );
        this.props.setCart([]);
      }
      this.props.setUserName(response.data.userName);
      this.props.setBalance(response.data.balance);
      this.props.setCustomerLinkId(response.data.customerLinkId);
      this.props.setDisplayName(response.data.displayName);
      this.props.setUserId(response.data.userId);
      this.props.onLogin();
      this.props.history.push("/");
      toast(<p>Welkom, {response.data.displayName}</p>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.loginForm) {
      formElementsArray.push({
        id: key,
        config: this.state.loginForm[key]
      });
    }

    return (
      <div className="Login">
        <div className="LoginBox">
          <h1>Inloggen</h1>
          <form onSubmit={this.loginHandler}>
            {formElementsArray.map(formElement => (
              <Input
                id={formElement.config.elementConfig.id}
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={event =>
                  this.inputChangedHandler(event, formElement.id)
                }
                label={formElement.config.label}
              />
            ))}
            <div className="Container">
              <Button id="buttonLogin" disabled={!this.state.formIsValid}>
                Inloggen
              </Button>
              <div className="Links">
                <NavLink id="forgot" to="/forgot">
                  Wachtwoord vergeten?
                </NavLink>
                <br />
                <NavLink id="register" to="/register">
                  Nog geen account?
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

//Redux: https://redux.js.org/
const mapStateToProps = state => {
  return {
    auth: state.login,
    cart: state.cart
  };
};

//Redux: https://redux.js.org/
const mapDispatchToProps = dispatch => {
  return {
    onLogin: () => dispatch({ type: "IS_AUTHENTICATED", value: true }),
    setToken: token => dispatch({ type: "SET_LOGINTOKEN", value: token }),
    setBalance: balance => dispatch({ type: "SET_BALANCE", value: balance }),
    setCustomerLinkId: customerLinkId =>
      dispatch({ type: "SET_CUSTOMERLINKID", value: customerLinkId }),
    setDisplayName: displayName =>
      dispatch({ type: "SET_DISPLAYNAME", value: displayName }),
    setSendEmailAddress: sendEmailAddress =>
      dispatch({ type: "SET_SENDEMAILADDRESS", value: sendEmailAddress }),
    setUserId: userId => dispatch({ type: "SET_USERID", value: userId }),
    setUserName: userName =>
      dispatch({ type: "SET_USERNAME", value: userName }),
    setCart: cart => dispatch({ type: "SET_CART", value: cart })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
