import React, { Component } from "react";

import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import "../Layout/Layout.css";
import Footer from "../../components/Footer/Footer";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import SideDrawerNavigation from "../../components/Navigation/SideDrawer/SideDrawerNavigation/SideDrawerNavigation";

/**
 * @author Elisa Straatman
 *
 * The layout of the application.
 */
class Layout extends Component {
  state = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    return (
      <>
        <div className="Stripe" />
        <Toolbar drawerToggle={this.sideDrawerToggleHandler} />
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        >
          <SideDrawerNavigation
            {...this.props}
            clicked={this.sideDrawerClosedHandler}
          />
        </SideDrawer>
        <main className="LayoutContent">{this.props.children}</main>
        <Footer />
      </>
    );
  }
}

export default Layout;
