import React from "react";

import "./Spinner.css";

/**
 * @author Donny Smit
 *
 * Simple spinner component.
 */
const spinner = () => <div className="Spinner" />;

export default spinner;
